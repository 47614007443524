.error-conn {

    min-height: 20rem;
    position: relative;

    &__ban {
        width: 3rem !important;
        height: auto;
        color: #dc3545;
        position: absolute;
        left: calc(50% - 2rem);
        top: calc(50% - 3rem);
    }

    &__signal {
        width: 6rem !important;
        height: auto;
        color: #ccc;
        position: absolute;
        left: calc(50% - 3rem);
        top: calc(50% - 3rem);
    }

    &__title {
        color: $color-primary;
        font-weight: bold;
        margin: 3rem 0;

        @include bp-down(s){
            margin: 2rem 0;
        }
    }

    &__image {
        width: 15rem;
        opacity: 0.3;

        @include bp-down(s){
            width: 10rem;
        }

        @include bp-down(xxs){
            width: 8rem;
        }
    }

    &__text {
        margin: 3rem 0 2rem;
        color: #444;

        @include bp-down(xxs){
            margin: 2rem 0 0;
        } 
    }

    &__button {
        margin-top: 3rem;
        margin-bottom: 5rem;

        @include bp-down(xxs){
            margin-top: 2rem;
            margin-bottom: 3rem;
        }
    }

}