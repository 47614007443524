.bg-gradient-blue {
    @include bg-gradient($zb-blue);
}

.bg-gradient-red {
    @include bg-gradient($red);
}

.border-gradient-blue {
    @include border-gradient($zb-blue);
}

.bg-gradient-primary {
    @include bg-gradient($color-primary);
}

.border-gradient-primary {
    @include border-gradient($color-primary);
}