.poll-admin {

    background-color: $bg-color-primary;
    min-height: 66.8vh;
    color: $color-secondary;

    &-info {
        border: 2px solid $color-primary;
        border-radius: 1rem;
        padding: .6rem 1rem;
        margin: 0 auto 1rem;

        & h1 {
            color: $color-primary;
        }

        & p {
            margin-bottom: 0;
        }

        & i {
            display: inline-block;
            width: 6rem;
            color: $color-primary;
        }
    }

    &-modal {
        & .modal-title {
            color: $color-primary;
        }
    }

    &-header {
        width: 100%;
        background-color: $color-primary-dark-30;
        display: flex;
        justify-content: space-between;

        &__new {
            border: .1rem solid #fff;
            width: 12rem; 
            margin: .5rem;

            @include bp-down(xs){
                width: 8rem;
            }
        }

        &__shortcut {
            border-right: 1px solid #fff;
        }

        &__polls, &__shortcut {
            color: #fff;
            font-weight: bold;
            &:hover, &:active, &:focus {
                color: #fff;
                outline: none;
                box-shadow: none;
            }
        }
    }
}