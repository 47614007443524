.poll-controls {

    display: inline-block;

    &-item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 3rem;

        @include bp-down(s) {
            width: 2.4rem;
        }

        &--fullscreen, &--results {
            width: 6rem;

            @include bp-down(s) {
                width: 5rem;
            }

            @include bp-down(xxs) {
                width: 4rem;
            }
        }

        & span {
            font-weight: bold;
            margin-top: .2rem;
        }

        &.disabled {
            opacity: .5;
        }
    }
}