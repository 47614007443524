/* added bootstrap rule for smaller screen */
/* #IMPORTANT: must be on top of bootstrap import to avoid overriding his styles */
@media (min-width: 400px){
    @for $i from 1 through 12 {
      .col-xs-#{$i} {
        flex: 0 0 100%/12*$i;
        max-width: 100%/12*$i;
      }
      .offset-xs-#{$i} {
        margin-left: 100%/12*$i;
      }
    }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Import zbin styles -- TODO: to remove and replace with common styles
@import './style/zbin.scss';


// Import functions (breakpoint)
@import './style/mixins.scss';

// Import default styles and variables
@import './style/common.scss';

//Toastify styles:
@import './style/toastify/toastify.scss';

// Override default modules style
@import './style/Modules/main.scss';

// Utility classes
@import './style/utility.scss';

// Import poll style
@import './style/poll.scss';

// legacy components style
@import './style/legacy/glyphicons.css';
@import './style/legacy/datatables.min.css';


// COMPONENTS

// _UI
@import './Components/_UI/Button/Button.scss';
@import './Components/_UI/Chart/DoughnutIcon.scss';
@import './Components/_UI/Chart/HistogramIcon.scss';
@import './Components/_UI/ErrorHandler/ConnectionError.scss';
@import './Components/_UI/ErrorHandler/PageNotFound.scss';
@import './Components/_UI/Pagination/Pagination.scss';
@import './Components/_UI/Select/Select.scss';
@import './Components/_UI/Toggle/Toggle.scss';
@import './Components/_UI/Modal/ModalMessage.scss';

// LoginPage
@import './Components/LoginPage/LoginPageHeader.scss';
@import './Components/LoginPage/TermsOfService.scss';

// Registration
@import './Components/RegisterPage/RegisterSuccess.scss';
@import './Components/RegisterPage/TermsConditions.scss';

// Navigation
@import './Components/Navigation/Navigation.scss';

// Footer
@import './Components/Footer/Footer.scss';
@import './Components/Legal/Legal.scss';

// Take a picture
@import './Components/TakePicture/TakePicture.scss';

// 
@import './Components/FileViewer/VideoViewer.scss';

// Survey
@import './Components/Survey/Survey.scss';

// Countdown
@import './Components/EventHeader/EventHeader.scss';

//LabelledIcons
@import './Components/LabelledIcons/LabelledIcons.scss';

//WarningIE
@import './Components/WarningIE/Warning.scss';

// Add To Calendar
@import './Components/AddToCalendar/AddToCalendar.scss';

// Countdown
@import './Components/Countdown/Countdown.scss';

// Confetti
@import './Components/Confetti/Confetti.scss';

// Live Page
@import './Components/LivePage/LivePageSectionTitle.scss';
@import './Components/LivePage/LivePageHeader.scss';
@import './Components/LivePage/LivePageAgenda.scss';
@import './Components/LivePage/LivePageTeamChat.scss';
@import './Components/LivePage/LivePageDocuments.scss';
@import './Components/LivePage/LivePageSessions.scss';
@import './Components/LivePage/LivePageVideo.scss';
@import './Components/LivePage/LivePagePhotos.scss';
@import './Components/LivePage/LivePagePolls.scss';
@import './Components/LivePage/LivePageEventTitle.scss';

// Polls
@import './Components/Poll/PollNew/PollTitle/PollTitle.scss';
@import './Components/Poll/PollNew/PollType/PollType.scss';
@import './Components/Poll/PollNew/PollTypeAnswer/PollTypeAnswer.scss';
@import './Components/Poll/PollNew/PollTypeChart/PollTypeChart.scss';
@import './Components/Poll/PollNew/PollQuestion/PollQuestion.scss';
@import './Components/Poll/PollNew/PollQuestion/PollQuestionHeader.scss';
@import './Components/Poll/PollNew/PollAnswer/PollAnswer.scss';
@import './Components/Poll/PollNew/PollAddAnswer/PollAddAnswer.scss';
@import './Components/Poll/PollList/PollItem.scss';
@import './Components/Poll/PollList/PollQuestionItem.scss';
@import './Components/Poll/PollChart/PollChartHistogram/PollChartHistogram.scss';
@import './Components/Poll/PollChart/PollChartRing/PollChartRing.scss';
@import './Components/Poll/PollAdmin/PollControls.scss';
@import './Components/Poll/PollUser/PollQuestion.scss';
@import './Components/Poll/PollUser/PollAnswer.scss';
@import './Components/Poll/PollUser/PollMessage.scss';

// Event
@import './Components/Event/EventEdit.scss';

// Modal
@import './Components/ModalTeamDetails/ModalTeamDetails.scss';

// Slides
@import './Components/Slides/Slide.scss';


// CONTAINERS

@import './Containers/LoginPage/LoginPage.scss';
@import './Containers/RegisterPage/RegisterPage.scss';
@import './Containers/ForgotPassword/ForgotPassword.scss';

@import './Containers/Chat/Chat.scss';
@import './Containers/Event/EventAdmin.scss';
@import './Containers/LivePage/LivePageUser.scss';
@import './Containers/LivePage/LivePageUser_china.scss';

@import './Containers/UsersList/UsersList.scss';

@import './Containers/Leaderboard/Leaderboard.scss';

@import './Containers/FacultyPanel/FacultyPanel.scss';

// Polls
@import './Containers/Poll/PollAdmin/PollAdmin.scss';
@import './Containers/Poll/PollAdmin/PollIntro.scss';
@import './Containers/Poll/PollAdmin/PollList.scss';
@import './Containers/Poll/PollAdmin/PollLive.scss';
@import './Containers/Poll/PollUser/PollUser.scss';
@import './Containers/Poll/PollUser/PollLive.scss';
@import './Containers/Poll/PollUser/PollPlanned.scss';