.poll-planned {
    @include bg-gradient($color-primary);

    &-body {
        padding: 0 3rem;

        @include bp-down(s){
            padding: 0 2rem;
        }

        & > * {
            padding: 2rem 0;
            border-bottom: .15rem solid;
            border-color: $color-primary;
            border-image: linear-gradient(right, $color-primary, #eee);
            border-image-slice: 1;

            @include bp-down(s){
                padding: 1rem 0;
            }
        }
    }

    &-footer {
        padding: 1rem;
        color: #fff;
        text-align: center;

        &-send {

            // #IMPORTANT: Using inline to have a div with size limited to the content.
            // Needed for showing this div as a button
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            &__icon {
                width: 4rem !important;
                height: 4rem !important;
                margin-bottom: .5rem;
            }
        }

    }
}