/***** Tecan Fonts *****/

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham-Bold.woff2') format('woff2'),
        url('./fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('./fonts/Gotham-Book.woff2') format('woff2'),
        url('./fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham-Medium.woff2') format('woff2'),
        url('./fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Electra LT Std Display';
    src: url('./fonts/ElectraLTStd-BoldDisplay.woff2') format('woff2'),
        url('./fonts/ElectraLTStd-BoldDisplay.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


/***** DM movers Fonts *****/

@font-face {
    font-family: 'Good Times';
    src: url('./fonts/good times rg.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
}

/***** placeholder override *****/

.form-control::-webkit-input-placeholder {
    color: #bbb !important;
}
.form-control::-moz-placeholder {
    color: #bbb !important;
}
.form-control:-ms-input-placeholder {
    color: #bbb !important;
}
.form-control::-ms-input-placeholder {
    color: #bbb !important;
}
.form-control::placeholder {
    color: #bbb !important;
}


/***** Colors override *****/
// https://pinetools.com/lighten-color

// Town Hall Colors
$color-blue-light: #bfdfe3;
$color-blue: #149bd6;
$color-blue-dark: #1a4055;
$color-orange: #EEAF00;
$color-green: #58A618;
$color-green-light: #469e99;

$color-primary: $color-blue;
$color-text: $color-blue-dark;
$bg-color-page: $color-blue;
$bg-color-box: #fff;
$bg-color-field: rgba($color-blue-light,.4);
$bg-color-button: $color-blue;

$color-primary-dark-30: #b42f0c;
$color-primary-light-80: #c1e8ff;
$color-secondary: #fff;

$bg-color-primary: rgba($color-primary, 0.6);
$bg-color-form: rgba($bg-color-primary, 0.8);

$color-text-black: #222;


$color-red: #F04E23;
$color-pink: #EA5297;
$color-grey-light: #C6C6C5;
$color-grey-middle: #9c9c98;

$navbar-height: 3rem;
$max-page-width: 100rem;

body {
    font-family: 'Open Sans', Arial, 'Times New Roman', serif;
    color: $color-text;
    background-color: $bg-color-page;
}


// video container frame proportions
.container-video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

.container-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/* loader */
.page-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    opacity: 1;
    // background: #fff;
    background: $color-blue;
}
.page-loader img {
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    width: 100px;
    position: absolute;
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}
.page-loader .spinner-border {
    color: $color-text;
}
.page-loader.available {
    opacity: 0;
    display: none;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


/* Box used to disable specific area on the page */
.box-hide-content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($bg-color-primary, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        text-shadow: 1px 1px 2px #fff;
        margin-bottom: 0;
        text-align: center;
    }
}


// Custom scroll bar

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $color-blue-light; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba($color-blue-dark,.9); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-blue-dark; 
}


// Poll Everywhere override styles
.pollev-app {

    & .hold-screen__logo {
        margin: 0 auto;
    }

}


/* small size */
@include bp-down(xxs){

    .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
        padding-right: 5px;
        padding-left: 5px;
    }

    .row {
        margin-right: -5px;
        margin-left: -5px;
    }

    [class*='col-'] {
        padding-right: 5px;
        padding-left: 5px;
    }

}


