.live-page-event-title {

    color: #fff;

    & p {
        display: inline-block;
        margin-bottom: 0;
    }

    img {
        width: 14rem;
        height: 17rem;

        @include bp-down(m) {
            width: 10rem;
            height: 13rem;
        }
    }

    &-group {
        @include bp-down(s){
            text-align: center;
        }
    }

    &__title {

        display: flex;
        align-items: center;

        &--2 {
            font-weight: bold;
            color: $color-blue-dark;
        }

        & .vertical-line {
            display: inline-block;
            border-left: 2px solid #fff;
            margin: 0 1.5rem;
            height: 1.5rem;
        }

        @include bp-down(s){
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;

            & .vertical-line {
                display: none;
            }
        }
    } 

    &__date {
        // font-weight: 100;
        &-day {
            display: inline-block;
            background-color: $color-blue-dark;
            font-weight: bold;
            padding: .5rem 1rem;
            margin-bottom: .5rem !important;
        }

        &-time {
            margin-left: 1rem;
        }

        @include bp-down(s){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

}