.poll-user-message {

    padding: 3rem;

    @include bp-down(m) {
        padding: 2rem;
    }

    @include bp-down(xs) {
        padding: 1rem;
    }

    &-header {

    }

    &-body {

        &-content {

            color: #fff;
            text-align: center;

            & h1 {
                font-weight: bold;
            }

            &__icons {
                margin: 2rem 0;
                display: inline-block;
                position: relative;
            }

            &__icon {
                width: 10rem !important;
                height: 10rem !important;

                @include bp-down(m) {
                    width: 8rem !important;
                    height: 8rem !important;
                }

                @include bp-down(xxs) {
                    width: 6rem !important;
                    height: 6rem !important;
                }

                &.slash {
                    position: absolute;
                    left: -5px;
                    top: -5px;
                }
            }
        }


    }

    &-footer {

        display: flex;
        justify-content: flex-end;

        &-send {

            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            cursor: pointer;
    
            &__icon {
                width: 2.5rem !important;
                height: 2.5rem;

                @include bp-down(xs) {
                    width: 2rem !important;
                    height: 2rem;
                }

                @include bp-down(xxs) {
                    width: 1.5rem !important;
                    height: 1.5rem;
                }
            }
        }
    }

}