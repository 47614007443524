.before-page-section-header {

    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 0 1rem;

    &__icon {
        height: 4rem;
        margin: 1rem 3rem;

        @include bp-down(xs){
            height: 3rem;
            margin: 1rem 2rem;
        }
    }

    &__title {
        font-weight: bold;
        text-align: center;
    }

    &__separator {
        border-top: .2rem solid #fff;
        margin-top: 0;
        width: 6rem;
    }

}