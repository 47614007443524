.poll-user-live {
    padding: 3rem;

    @include bp-down(s) {
        padding: 2rem;
    }

    @include bp-down(xs) {
        padding: 1.5rem;
    }
}