.slide {
    //padding: 1rem;
    border: 1px solid $color-blue-dark;
    margin-bottom: 1rem;

    &-wrapper {
        display: flex;
        justify-content: center;
    }
    &__image {
        width: 50rem;
    }
}
