@mixin bp-up($size) {
    @if $size == xxs {
        @media (max-width: 399px) { @content; }
    } @else if $size == xs {
        @media (min-width: 400px) { @content; }
    } @else if $size == s {
        @media (min-width: 576px) { @content; }
    } @else if $size == m {
        @media (min-width: 768px) { @content; }
    } @else if $size == l {
        @media (min-width: 992px) { @content; }
    } @else if $size == xl {
        @media (min-width: 1200px) { @content; }
    } @else if $size == xxl {
        @media (min-width: 1800px) { @content; }
    }
}

// Breakpoint down
@mixin bp-down($size) {
    @if $size == xxxs {
        @media (max-width: 320px) { @content; }
    } @else if $size == xxs {
        @media (max-width: 399px) { @content; }
    } @else if $size == xs {
        @media (max-width: 575.98px) { @content; }
    } @else if $size == s {
        @media (max-width: 767.98px) { @content; }
    } @else if $size == m {
        @media (max-width: 991.98px) { @content; }
    } @else if $size == l {
        @media (max-width: 1199.98px) { @content; }
    } @else if $size == xl {
        @media (max-width: 1799.98px) { @content; }
    }
}

// Truncate text to line x
@mixin ellipsizeText($lines:1){
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

// #IMPORTANT: fallback IE11
// Background gradient polls
@mixin bg-gradient($color){
    /* can be treated like a fallback */
    background-color: $color;
    /* will be "on top", if browser supports it */
    background-image: linear-gradient(to right, $color, rgba($color, 0.4));
    /* these will reset other properties, like background-position, but it does know what you mean */
    background: $color;
    background: linear-gradient(to right, $color, rgba($color, 0.4));
}

// #IMPORTANT: fallback IE11
// Background gradient polls
@mixin border-gradient($color){
    border: .15rem solid;
    border-color: $color;
    border-image: linear-gradient(to right, $color, rgba($color, 0.4));
    border-image-slice: 1;
}

@mixin bg-page($bgposition:center center){
    background: url('/assets/images/bg-page-new.jpg') no-repeat center center;
    background-size: cover;
    //background-repeat: repeat;
    //background-position: $bgposition;

    @include bp-down(m){
        //background-position: center center;
    }
}