.react-datepicker {
    
    &-wrapper {
        width: 100%;
    }

    &-popper {
        z-index: 9999;
    }

}

@include bp-down(xs) {
    
    .react-datepicker {

        &__navigation {
            border-width: .5rem !important;
        }

        &__current-month, &-time__header {
            font-size: 1rem !important;
        }

        &__triangle {
            display: none;
        }

        &__header {
            padding-top: 5px;
        }

        &__time-container, &__time-box {
            width: 5rem !important;
        }

        &__day-name, &__day, &__time-name {
            width: 1.8rem !important;
            line-height: 1.8rem !important;
        }

        &__time-list {
            height: 13rem !important;
        }
    }

}

@include bp-down(xxs) {

    .react-datepicker {

        &__current-month, &-time__header {
            font-size: .9rem !important;
        }
        
        &__day-name, &__day, &__time-name {
            width: 1.6rem !important;
            line-height: 1.6rem !important;
        }

        &__time-container, &__time-box {
            width: 4.5rem !important;
        }

        &__time-list {
            height: 12rem !important;
        }
    }
}