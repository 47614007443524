.event-admin {
    color: $color-secondary;

    & .event-list {
        color: $color-secondary;
    }

    &-buttons {
        display: flex;
        justify-content: flex-end;
    }
}