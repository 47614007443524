/* OVERRIDE CSS STYLE nuka-carousel module */

.slider {
    z-index: 3;
}

.slider-control-centerleft button, .slider-control-centerright button {
    height: 3rem;
    width: 3rem;
    outline: none;
    border-radius: 50%;
    border: 2px solid #fff;
    opacity: 0.7;
    background-color: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .6);

    @include bp-down(l){
        height: 2.6rem;
        width: 2.6rem;
    }

    @include bp-down(m){
        height: 2.2rem;
        width: 2.2rem;
    }

    @include bp-down(s){
        height: 1.8rem;
        width: 1.8rem;
        border: 1px solid #fff;
    }

    & svg.svg-inline--fa.fa-w-10 {
        width: 1rem;
        height: auto;

        @include bp-down(m){
            width: .8rem;
        }

        @include bp-down(s){
            width: .6rem;
        }
    }
}

.slider-control-centerleft {
    left: 1rem !important;

    @include bp-down(m){
        left: .8rem !important;
    }

    @include bp-down(s){
        left: .6rem !important;
    }    
}

.slider-control-centerright {
    right: 1rem !important;

    @include bp-down(m){
        right: .8rem !important;
    }

    @include bp-down(s){
        right: .6rem !important;
    } 
}

.slider-control-bottomcenter {
    & ul li div {
        width: .7rem;
        height: .7rem;
        cursor: pointer;

        @include bp-down(m){
            width: .6rem;
            height: .6rem;
        }

        @include bp-down(s){
            width: .5rem;
            height: .5rem;
        }        
    }
}