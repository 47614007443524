.live-page-agenda {

    &-header {
        margin-top: 1rem;
        margin-bottom: .6rem;
    }

    &-day, &-timezone {
        margin-bottom: 0;
        border-radius: 0;
        width: 100%;
        background-color: $bg-color-box;
        border-color: $bg-color-box;
        color: $color-text;
        font-weight: bold;
        // custom arrow right for select box
        -webkit-appearance: none;
        -moz-appearance: none;
        // background-repeat: no-repeat;
        // background-image: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
        // background-repeat: no-repeat;
        // background-position: 95% 40%;

        &:focus {
            background-color: $bg-color-box;
            border-color: $bg-color-box;
            color: $color-text;
        }
    }

    &-content {
        height: 30rem;
        overflow: hidden;
        overflow-y: auto;
        background-color: $bg-color-box;

        @include bp-down(s){
            height: 20rem;
        }

    }

    &-scrollicon {
        position: absolute;
        right: 0;
        top: calc(50% - .7rem);
        color: #fff;
        width: 1rem !important;
        height: 1.4rem;
        opacity: .6;
    }

    &-legend {

        padding: .5rem 1rem;
        border: 1px solid #fff;
        border-radius: 4px;
        margin-bottom: 1rem;

        &-item {

            display: flex;
            align-items: center;
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }

            & img {
                width: 1.6rem;
                margin-right: 1rem;
            }

            & p {
                margin-bottom: 0;
                font-size: .7rem;
            }
        }
    }

    &-item {

        padding: .5rem 1.5rem;
        margin: .5rem 0;
        cursor: default;

        &:last-child {
            margin-bottom: calc(4rem + 28vw);
        }

        &__header {
            position: relative;

            &-icon {
                position: absolute;
                left: -1rem;
                top: 0px;
                font-size: 1.6rem;
                line-height: 1;
            }
        }

        &__time {
            font-weight: bold;
            margin-bottom: 0;
        }

        &__icon {
            width: 1.6rem;
        }

        &__title {
            // color: $color-primary;
            position: relative;
            margin-bottom: .2rem;

            & span {
                display: none;
                position: absolute;
                top: -14px;
                left: -32px;

                @include bp-down(s){
                    top: -12px;
                    left: -25px;
                }

                @include bp-down(xxs){
                    top: -8px;
                    left: -22px;
                }
            }
        }

        &__descr {
            margin-bottom: .2rem;
        }

        &__author {
            text-align: right;
            font-style: italic;
        }

        &__btn-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        &__btn, &__btn:hover , &__btn:active, &__btn:focus {
            color: #fff !important;
            background-color: $color-primary !important;
            border-color: $color-primary !important;
            outline: none !important;
            box-shadow: none !important;
            padding: .2rem .5rem;
        }

        &.active {

            background-color: $color-green-light;
            color: $color-secondary;

            & .live-page-agenda-item__time {
                color: $color-secondary;
                font-weight: 700;
            }

            & .live-page-agenda-item__title {
                color: $color-secondary;
                font-weight: 700;
                font-size: 110%;

                & span {
                    display: block;
                }
            }
        }
    }

    &-separator {
        border-top: .1rem solid $color-blue;
        margin-top: .5rem;
        margin-bottom: .5rem;
        width: 80%;
    }


    .select_box {
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .select_box:after {
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $color-blue-dark;
        position: absolute;
        top: 38%;
        right: 12px;
        content: "";
        z-index: 98;
    }

    .select_box select {
        width: 100%;
        position: relative;
    }

}