.leaderboard {

    &-wrapper {
        min-height: 80vh;
        background-image: linear-gradient(rgba(0, 119, 200, 0.3), transparent), url(/assets/images/bg-page-sm.jpg);
        background-repeat: repeat;
        background-position: center top;
    }

    padding-top: 4rem;

    &-header {
        text-align: center;
        margin-bottom: 3rem;

        &__title {
            font-weight: 700;
        }
    }

    &-content {
        margin-bottom: 2rem;
    }

    &-back.btn.blue {
        color: $color-blue;
        background-color: $color-text;
        border-color: $color-text;
    }

    &-table {

        border: 6px solid $color-primary;

        &-head {
            background-color: $color-primary;
            border-bottom: 2px solid $color-blue-dark;

            & th {
                padding: .5rem 1rem;
            }

            &__stars {
                width: 14rem !important;
            }
        }

        &-body {

            & tr:nth-of-type(even) {
                background-color: $color-blue-light;
            }
            & tr:nth-of-type(odd) {
                background-color: $color-text;
            }

            & td {
                padding: .5rem 1rem;
                color: $color-primary;
            }

            &__rank {

                & span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 700;
                    font-size: 1.2rem;
                    width: 2.6rem;
                    height: 2.6rem;
                    border-radius: 50%;
                    border: 2px solid $color-blue;
                }
            }

            &__player {
                display: inline-flex;
                flex-direction: column;

                & h1 {
                    font-weight: 700;
                    margin-bottom: 0;
                }

                & h2 {
                    margin-bottom: 0;
                }
            }

            &__points {
                font-size: 1.2rem;
                font-weight: 700;
            }

            &__stars {
                text-align: right;
                padding-right: 2rem !important;

                & .fa-star {
                    width: 1.6rem !important;
                    height: 1.6rem;
                    padding: .2rem;
                    margin-right: .2rem;
                    color: $color-primary;
                    background-color: $color-text;
                    border: 1px solid $color-blue;
                    border-radius: 50%;
                }

            }

        }
    }


    /* Datatable classes */

    & .dataTables_processing {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-blue-dark;
        font-size: 1.2rem;
        font-weight: 700;
    }

    & .dataTables_paginate {

        margin-top: 1rem;
        margin-bottom: 1rem;
        float: right;

        & .paginate_button {
            background-color: $color-text;
            color: $color-blue;
            font-size: 1.1rem;
            border: 1px solid $color-blue;
            padding: .3rem .6rem;
            margin: .3rem;
            outline: none;
            cursor: pointer;

            &.current {
                background-color: $color-blue;
                color: $color-text;
                font-weight: bold;
            }

            &.disabled {
                cursor: default;
                background-color: #ccc;
            }
        }

    }
}