.legal-notes {

    padding-bottom: 1rem;
    padding-top: 3rem;
    padding-bottom: 6rem;
    color: $color-blue-light;

    &-wrapper {
        background-image: url('/assets/images/bg-page-new.jpg');
        background-repeat: repeat;
        background-position: center top;
        background-size: cover;
    }

    &-content {
        background-color: rgba($color-blue-dark, .8);
        padding: 2rem;
    }

    &-title {
        
        padding-bottom: 2rem;
        font-size: 1.8rem;

        @include bp-down(m) {
            font-size: 1.62rem;
        }
    
        @include bp-down(s) {
            font-size: 1.44rem;
            padding-bottom: 1.5rem;
        }
    
        @include bp-down(xxs) {
            font-size: 1.26rem;
        }
    }

    &-subtitle {
        font-size: 1.6rem;

        @include bp-down(m) {
            font-size: 1.44rem;
        }
    
        @include bp-down(s) {
            font-size: 1.28rem;
        }
    
        @include bp-down(xxs) {
            font-size: 1.12rem;
        }
    }

    & h3 {
        font-size: 1.4rem;

        @include bp-down(m) {
            font-size: 1.26rem;
        }
    
        @include bp-down(s) {
            font-size: 1.12rem;
        }
    
        @include bp-down(xxs) {
            font-size: 0.98rem;
        }
    }

    & h4 {
        font-size: 1.2rem ;

        @include bp-down(m) {
            font-size: 1.08rem ;
        }
    
        @include bp-down(s) {
            font-size: .96rem ;
        }
    
        @include bp-down(xxs) {
            font-size: .84rem ;
        }
    }

    & a {
        color: $color-blue-light;
    }


}