.live-page-poll {

    margin-top: 1rem;
    background-color: white;

    &-questions{
        display: flex;
        justify-content: center;

        &__frame {
            width: 100%;
            // min-height: 35rem;
            border: none;            
        }

    }
}