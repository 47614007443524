.select.form-control {

    font-size: 1rem;
    padding: 0.4rem 1rem;

    @include bp-down(m) {
        font-size: .9rem;
        padding: 0.35rem 0.8rem;
    }

    @include bp-down(s) {
        font-size: .8rem;
        padding: 0.3rem 0.7rem;
    }

    @include bp-down(xs) {
        font-size: .7rem;
        padding: 0.25rem 0.6rem;
    }
    
}