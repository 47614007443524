#confetti {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 1;

    &.fade-out {
        animation: fadeout 2.2s ease-out;
    }
}


@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}