@import './zbin.scss';

/* FONT SIZE */

.u-font-size-50 {
    font-size: 5rem !important;

    @include bp-down(m) {
        font-size: 4.5rem !important;
    }

    @include bp-down(s) {
        font-size: 4rem !important;
    }

    @include bp-down(xs) {
        font-size: 3.5rem !important;
    }

    @include bp-down(xxs) {
        font-size: 3rem !important;
    }

    @include bp-down(xxxs) {
        font-size: 2.5rem !important;
    }
}

.u-font-size-40 {
    font-size: 4rem !important;

    @include bp-down(m) {
        font-size: 3.6rem !important;
    }

    @include bp-down(s) {
        font-size: 3.2rem !important;
    }

    @include bp-down(xs) {
        font-size: 2.8rem !important;
    }

    @include bp-down(xxs) {
        font-size: 2.4rem !important;
    }

    @include bp-down(xxxs) {
        font-size: 2rem !important;
    }
}

.u-font-size-35 {
    font-size: 3.5rem !important;

    @include bp-down(l) {
        font-size: 3.3rem !important;
    }

    @include bp-down(m) {
        font-size: 3.2rem !important;
    }

    @include bp-down(s) {
        font-size: 2.9rem !important;
    }

    @include bp-down(xs) {
        font-size: 2.6rem !important;
    }

    @include bp-down(xxs) {
        font-size: 2.3rem !important;
    }

    @include bp-down(xxxs) {
        font-size: 2rem !important;
    }
}

.u-font-size-30 {
    font-size: 3rem !important;

    @include bp-down(m) {
        font-size: 2.7rem !important;
    }

    @include bp-down(s) {
        font-size: 2.4rem !important;
    }

    @include bp-down(xs) {
        font-size: 2.1rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.8rem !important;
    }

    @include bp-down(xxxs) {
        font-size: 1.5rem !important;
    }
}

.u-font-size-25 {
    font-size: 2.5rem !important;

    @include bp-down(l) {
        font-size: 2.3rem !important;
    }

    @include bp-down(m) {
        font-size: 2.2rem !important;
    }

    @include bp-down(s) {
        font-size: 1.9rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.6rem !important;
    }

}

.u-font-size-20 {
    font-size: 2rem !important;

    @include bp-down(m) {
        font-size: 1.8rem !important;
    }

    @include bp-down(s) {
        font-size: 1.6rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.4rem !important;
    }

}

.u-font-size-18 {
    font-size: 1.8rem !important;

    @include bp-down(m) {
        font-size: 1.62rem !important;
    }

    @include bp-down(s) {
        font-size: 1.44rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.26rem !important;
    }

}

.u-font-size-17 {
    font-size: 1.7rem !important;

    @include bp-down(m) {
        font-size: 1.54rem !important;
    }

    @include bp-down(s) {
        font-size: 1.38rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.22rem !important;
    }
}

.u-font-size-16 {
    font-size: 1.6rem !important;

    @include bp-down(m) {
        font-size: 1.46rem !important;
    }

    @include bp-down(s) {
        font-size: 1.32rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.18rem !important;
    }
}

.u-font-size-15 {
    font-size: 1.5rem !important;

    @include bp-down(m) {
        font-size: 1.38rem !important;
    }

    @include bp-down(s) {
        font-size: 1.26rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.14rem !important;
    }

}

.u-font-size-14 {
    font-size: 1.4rem !important;

    @include bp-down(m) {
        font-size: 1.3rem !important;
    }

    @include bp-down(s) {
        font-size: 1.2rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.1rem !important;
    }

}

.u-font-size-13 {
    font-size: 1.3rem !important;

    @include bp-down(m) {
        font-size: 1.21rem !important;
    }

    @include bp-down(s) {
        font-size: 1.12rem !important;
    }

    @include bp-down(xxs) {
        font-size: 1.03rem !important;
    }
}

.u-font-size-12 {
    font-size: 1.2rem !important;

    @include bp-down(m) {
        font-size: 1.12rem !important;
    }

    @include bp-down(s) {
        font-size: 1.04rem !important;
    }

    @include bp-down(xxs) {
        font-size: .96rem !important;
    }
}

.u-font-size-11 {
    font-size: 1.1rem !important;

    @include bp-down(l) {
        font-size: 1.04rem !important;
    }

    @include bp-down(m) {
        font-size: .97rem !important;
    }

    @include bp-down(s) {
        font-size: .9rem !important;
    }

    @include bp-down(xs) {
        font-size: .83rem !important;
    }
}

.u-font-size-10 {
    font-size: 1rem !important;

    @include bp-down(l) {
        font-size: .95rem !important;
    }

    @include bp-down(m) {
        font-size: .9rem !important;
    }

    @include bp-down(s) {
        font-size: .85rem !important;
    }

    @include bp-down(xs) {
        font-size: .8rem !important;
    }
}

.u-font-size-9 {
    font-size: .9rem !important;

    @include bp-down(m) {
        font-size: .86rem !important;
    }

    @include bp-down(s) {
        font-size: .82rem !important;
    }

    @include bp-down(xxs) {
        font-size: .78rem !important;
    }  
}

.u-font-size-8 {
    font-size: .8rem !important;

    @include bp-down(m) {
        font-size: .77rem !important;
    }

    @include bp-down(s) {
        font-size: .74rem !important;
    }

    @include bp-down(xxs) {
        font-size: .71rem !important;
    } 
}

.u-font-size-7 {
    font-size: .7rem !important;

    @include bp-down(m) {
        font-size: .78rem !important;
    }

    @include bp-down(s) {
        font-size: .76rem !important;
    }

    @include bp-down(xxs) {
        font-size: .74rem !important;
    } 
}

.u-font-size-6 {
    font-size: .6rem !important;

    @include bp-down(m) {
        font-size: .58rem !important;
    }

    @include bp-down(s) {
        font-size: .56rem !important;
    }

    @include bp-down(xxs) {
        font-size: .54rem !important;
    } 
}


/* FLEX */
.u-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.u-flex-col {
    flex-direction: column;
}

.u-margin-auto {
    margin: 0 auto;
}


/* MARGINS */
.mt-6 { margin-top: 4rem !important; }
.mb-6 { margin-bottom: 4rem !important; }
.mr-6 { margin-top: 4rem !important; }
.ml-6 { margin-bottom: 4rem !important; }
.mt-7 { margin-top: 5rem !important; }
.mb-7 { margin-bottom: 5rem !important; }
.mr-7 { margin-top: 5rem !important; }
.ml-7 { margin-bottom: 5rem !important; }

/* cursor */
.cursor-pointer { cursor: pointer; }
.cursor-default { cursor: default; }

/* border, shadow */
.border-none { border: none;}

/* video border */
.border-video {
    border: solid 1px;
    border-color: #e6e6e6;
}

.color-blue {
    color: $zb-blue;
}

.color-red {
    color: $color-red;
}

.color-primary {
    color: $color-primary;
}