.live-page-documents {

    // border: 1px solid $color-blue-light;
    // background-color: $color-blue-light;
    // padding: 2rem;
    margin: 0 auto;

    &-header {
        max-width: 60rem;
        margin: 0 auto;
    }

    &__title {

        margin-bottom: .5rem;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-blue-dark;

        @include bp-down(s){
            margin-top: 0rem;
        }
    }

    &__subtitle {
        margin-bottom: 2rem;
        text-align: center;
    }

    &__separator {
        width: 8rem;
        margin: 0 auto 1rem;
        border-top: .2rem solid $color-blue-dark;
    }

    &-list {
        max-width: 80rem;
        margin: 0 auto;
        justify-content: center;
        height: 15rem;
        background-color: $color-blue-light;

        @include bp-down(m){
            height: auto;
            min-height: 10rem;
        }

        // & > div:not(:last-child){
            & .live-page-documents-item {
                border-bottom: 1px solid $color-text;
            }
        // }
    }

    &-item {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        margin-top: 1rem;
        cursor: pointer;
        font-weight: bold;

        @include bp-down(xs){
            margin: .6rem .2rem;
        }

        @include bp-down(xxs){
            margin: .5rem 0;
        }

        &__text {
            margin-bottom: 0;
            margin-left: .5rem;
        }

        &__icon {
            width: 2.2rem !important;
            min-width: 2.2rem;            // IE bug
            height: 2rem;
            color: $color-blue-dark;
        }
    }
}