.interactive-page-login {
    min-height: 80vh;
    // background-image: url('/assets/images/bg-ball-bw.jpg');
    // background-size: cover;
    // background-position: bottom;

    &-header {
        margin-top: 3rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;

        @include bp-down(s) {
            text-align: center;
            margin-right: 0;
            margin-top: 2rem;
        }

        & h1 {
            margin-bottom: 0.2rem;
        }
    }

    &-countdown {
        text-align: center;
        margin-bottom: 2rem;

        @include bp-down(s) {
            margin-bottom: 1rem;
        }
    }
}

.interactive-page {
    &-form {
        margin-top: 2rem;
        margin-bottom: 3rem;
        width: 40rem;
        max-width: 95%;
        position: relative;

        @include bp-down(s) {
            width: 25rem;
            margin-top: 1rem;
        }

        @include bp-down(xs) {
            border-width: 0.3rem;
        }

        @include bp-down(xxs) {
            margin-top: 0rem;
            margin-bottom: 2rem;
            border-width: 0.2rem;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-spinner-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;
        }

        &-tabs {
            margin-bottom: 1rem;

            &--disabled {
                pointer-events: none;
            }

            &-update,
            &-register {
                display: flex;
                justify-content: center;
                align-items: center;

                &.active {
                    & hr {
                        display: block;
                    }
                }
            }

            &__title {
                color: $bg-color-box;
            }

            &__link,
            &__link:hover {
                width: 100%;
                color: $color-primary;
                text-align: center;
            }

            &__selector {
                margin-top: 0.3rem;
                margin-bottom: 0;
                border-top: 0.3rem solid $bg-color-box;
                width: 70%;
            }
        }

        &-content {
            padding: 2rem;
            background-color: $bg-color-box;

            &--disabled {
                pointer-events: none;
            }

            & a {
                color: $color-blue-dark;
                text-decoration: underline;
            }
        }

        &__title {
            font-size: 1.8rem;
            font-weight: bold;
            text-align: center;

            @include bp-down(s) {
                font-size: 1.6rem;
            }

            @include bp-down(xxs) {
                font-size: 1.4rem;
            }
        }

        &__subtitle {
            font-size: 1.2rem;
            text-align: center;

            @include bp-down(s) {
                font-size: 1rem;
            }

            @include bp-down(xxs) {
                font-size: 0.9rem;
            }
        }

        &__separator {
            width: 30%;
            height: 1rem;
            border-top: 5px solid rgba($color-text, 0.9);

            @include bp-down(xs) {
                border-top: 3px solid rgba($color-text, 0.9);
                margin-top: 0.5rem;
            }
        }

        &__group {
            margin-bottom: 1rem;
        }

        &__label {
            margin-bottom: 0.2rem;
            font-size: 1.1rem;

            &--disabled {
                opacity: 0.7;
            }

            &--sm {
                font-size: 1rem;
            }

            & .checkbox label {
                margin-bottom: 0;
            }

            @include bp-down(s) {
                font-size: 1rem;

                &--sm {
                    font-size: 0.9rem;
                }
            }

            @include bp-down(xs) {
                font-size: 0.9rem;

                &--sm {
                    font-size: 0.8rem;
                }
            }
        }

        &__field,
        &__field:focus,
        & .react-datepicker__input-container input,
        & .react-datepicker__input-container input:focus {
            // border: 1px solid rgba($color-primary,.6);
            border: none;
            outline: none;
            background-color: $bg-color-field;
            border-radius: 0;
            height: 2.5rem;

            & option {
                background-color: $bg-color-box;
            }

            @include bp-down(xs) {
                height: 2rem;
            }
        }

        & .input-group-addon {
            background-color: $bg-color-field;
            line-height: 2.5rem;
            height: 2.5rem;
            padding: 0 1rem;
            border-left: 2px solid $color-blue-dark;

            @include bp-down(xs) {
                line-height: 2rem;
                height: 2rem;
                padding: 0 0.5rem;
            }
        }

        // Override datepicker style
        & .react-datepicker__input-container input,
        & .react-datepicker__input-container input:focus {
            // border: 1px solid rgba($color-primary,.6);
            border: none !important;
            outline: none !important;
            background-color: $bg-color-field !important;
            border-radius: 0 !important;
            height: 2.5rem !important;

            @include bp-down(xs) {
                height: 2rem !important;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 2rem;

            @include bp-down(s) {
                flex-direction: column;
            }
        }

        &-picture {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;

            &-btn {
                display: flex;
                align-items: center;
                padding: 0.3rem 0.6rem;
                border: 1px solid $color-blue-dark;
                background-color: $color-blue-dark;
                cursor: pointer;

                &__icon {
                    width: 1.6rem !important;
                    height: 1.6rem;
                    padding: 0.3rem;
                    margin-right: 0.5rem;
                    color: $color-primary;
                    background-color: $color-text;
                    border-radius: 50%;
                }
            }

            &-preview {
                width: 8rem;
                margin-top: 0.5rem;
            }
        }

        &__submit {
            border: 1px solid $color-primary;
        }

        & .datetime-picker {
            &-calendar {
                position: absolute;
                width: 1.2rem;
                height: 1.2rem;
                top: 0.5rem;
                right: 0.5rem;
            }
        }

        & .react-datepicker {
            &-wrapper {
                width: 100%;
            }

            &__input-container {
                & input {
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 0.75rem;
                    font-size: 0.8rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }
        }

        & .forgotPasswordLink {
            color: $color-primary;
            text-decoration: none;
        }

        & .form-control {
            color: $color-text;
        }

        & select {
            // custom arrow right for select box
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
            background-repeat: no-repeat;
            background-position: 95% 40%;
        }

        & .valid-feedback,
        & .invalid-feedback {
            font-weight: bold;
            width: auto;
        }
    }
}
