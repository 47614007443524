.modal-message {

    & .modal-header {
        color: #fff;
        padding: .6rem 1rem;

        & .modal-title {
            font-size: 1.3rem;
        }
    }

    & .modal-body {
        min-height: 6rem;
        color: $color-blue-dark;
    }

    & .modal-footer {
        padding: .5rem;

        & .btn {
            color: #fff;
        }
    }

    &.success {
    
        & .modal {

            &-header {
                background-color: $color-green;
            }

            &-footer .btn {
                background-color: $color-green;
            }
        }
    }

    &.warning {

        & .modal {

            &-header {
                background-color: $color-orange;
            }

            &-footer .btn {
                background-color: $color-orange;
            }
        }
    }

    &.info {

        & .modal {

            &-header {
                background-color: $color-grey-light;
            }

            &-footer .btn {
                background-color: $color-grey-light;
            }
        }
    }

    &.error {

        & .modal {

            &-header {
                background-color: $color-red;
            }

            &-footer .btn {
                background-color: $color-red;
            }
        }
    }
}