@import './SurveyOptions/SurveyOptions.scss';
@import './SurveyRating/SurveyRating.scss';
@import './SurveySuggest/SurveySuggest.scss';
@import './SurveyThumbs/SurveyThumbs.scss';

.evaluation-survey {

    &-wrapper {
        background-image: url(/assets/images/bg-page-new.jpg); /* fallback */
        background-repeat: repeat;
        background-position: left top;
        background-color: $bg-color-page;
        background-size: cover;
    }

    padding-bottom: 6rem;

    @include bp-down(s) {
        padding-bottom: 3rem;
    }

    &-title {
        color: $color-blue;
        margin-bottom: 4rem;

        @include bp-down(s) {
            margin-bottom: 3rem;
        }
    }

    &-form {

        padding: 2rem;
        border: 2px solid $color-blue-dark;
        border-radius: 20px;
        background-color: rgba(#fff, .8);

        @include bp-down(s) {
            padding: 1.5rem;
        }

        @include bp-down(xs) {
            padding: 1rem;
        }
        
        &-title {
            display: block;
            width: 16rem;
            padding: 0 1.5rem;
            margin: -3.4rem auto 2rem auto;
            height: 2.5rem;
            background-color: $color-blue-dark;
            color: #fff;
            line-height: 2.5rem;
            border-radius: 10px;

            @include bp-down(s) {
                width: 12rem;
                height: 2.2rem;
                line-height: 2.2rem;
                margin: -3.2rem auto 2rem auto;
            }

            @include bp-down(xs) {
                width: 12rem;
                height: 2.2rem;
                line-height: 2.2rem;
                margin: -2.2rem auto 2rem auto;
            }
        }

        &-question {

            & h1 {
                color: $color-blue-dark;
            }
        }

        &-question:not(:nth-last-child(2)) {
            border-bottom: 1px solid $color-blue;
            padding-bottom: 2rem;
            margin-bottom: 2rem;

            @include bp-down(s) {
                padding-bottom: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }
    }


    // -- COMPLETED PAGE
    &-completed {

        padding-top: 4rem;
        min-height: 50vh;
        color: #fff;

        &-buttons {

            flex-wrap: wrap;
            margin-bottom: 6rem;

            @include bp-down(s){
                margin-bottom: 4rem;
            }


            & > * {
                margin: 0 10px;
            }
        }
    }


}