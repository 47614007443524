.poll-question-header {
    width: 100%;
    position: relative;
    @include bg-gradient($color-primary);
    padding: .3rem 1rem;
    color: #fff;
    font-weight: bold;

    &.error {
        @include bg-gradient($red);
    }

    &__collapse {
        width: .8rem !important;
        height: 1rem !important;
        margin-left: .5rem;
    }

    &__title {
        padding-right: 1rem;
        margin-right: 1rem;
        border-right: .1rem solid #fff;
    }

    &__text {
        font-weight: normal;
    }

    &__delete {
        position: absolute;
        width: 1rem !important;
        height: 1rem !important;
        right: .5rem;
        top: .5rem;
        z-index: 3;
        cursor: pointer;

        @include bp-down(s){
            width: .8rem !important;
            height: .8rem !important;
        }
    }
}