.polls-list {

    color: $color-text-black;

    &-changes {
        bottom: 1rem;
        left: 0;
        right: 0;
        position: fixed;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;

        &-content {
            background-color: $color-primary;
            display: flex;
            justify-content: center;
            box-shadow: 0 7px 4px -4px rgba(0, 0, 0, 0.3), 0 5px 12px 4px rgba(0, 0, 0, 0.3), 0 12px 17px 2px rgba(0, 0, 0, 0.3);
        }
    }
}