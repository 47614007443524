.faculty-panel {
    background-image: url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: left top;
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 4rem;

    @include bp-down(m){
        padding-top: 2rem;

        & .live-page-question-chat-wrapper {
            padding-top: 2rem;
        }
    }

    &-content {
        max-width: $max-page-width;
        margin: 0 auto;
    }
}