.poll-admin-live {

    background-color: #fff;
    color: $color-text-black;
    // #IMPORTANT: manage fullscreen on safary
    width: 100%;
    height: 100%;

    &:fullscreen {

        * {
            cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjbQg61aAAAADUlEQVQYV2P4//8/IwAI/QL/+TZZdwAAAABJRU5ErkJggg=='),
            // url(images/blank.cur),
            // none !important;
        }
        
        & .container {
            width: 95% !important;
            max-width: 95% !important;
        }

        & .poll-chart-histogram {
            max-width: 95%;
            margin: 0 auto;
        }

        & .poll-admin-live {

            &-header {
                padding: 1rem 2rem;
                font-size: 2rem !important;
            }

            &-body {
                &__title {
                    padding: 2rem;
                    &-text {
                        font-size: 2rem !important;
                    }
                }
            }

            // controls fixed on bottom
            &-footer {
                position: absolute;
                bottom: .5rem;
                width: 100%;

                &__pages {
                    font-size: 1.3rem;
                }

                &__controls {
                    // display: none;
                }
            }
        }

        & .poll-chart-ring-main {
            flex: 0 0 40%;
            max-width: 40%;
        }

        & .poll-chart-ring-legend {
            flex: 0 0 60%;
            max-width: 60%;
        }

        /*** START - legend charts and text ***/
        & .poll-chart-ring-legend-items__title, .poll-chart-histogram-legend-items__title {
            font-size: 1.5rem !important;
        }

        & .poll-chart-ring-legend-items__item {

            &-chart {
                width: 6rem;
                height: 6rem;
                margin-bottom: .4rem;
            }

            & .doughnut-border-in {
                padding-top: .6rem;
                font-size: 1.4rem;
            }

            & span {
                font-size: 1.4rem !important;
            }
        }

        & .poll-chart-histogram-legend-items {
            margin-right: -8rem;
        }

        & .poll-chart-histogram-legend-items__item {

            &-value {
                font-size: 1.5rem;
                width: 3.5rem;
            }

            &-text {
                font-size: 1.4rem !important;
            }
        }
        /*** END - legend charts and text **/

    }

    &-header {
        color: #fff;
        font-weight: bold;
        padding: .4rem 1rem;
    }

    &-body {

        &__title {
            display: flex;
            padding: 1rem;
            align-items: center;
            color: $color-primary;

            &-text {
                margin: 0;
                span {
                    font-weight: bold;
                }
            }
        }
    }

    &-footer {

        padding: 1rem;
        display: flex;
        justify-content: space-between; 
        align-items: flex-end;

        @include bp-down(m) {
            padding: .2rem .5rem;
        }

        @include bp-down(xs) {
            flex-direction: column;
            align-items: flex-start;
        }

        &__controls {
            display: flex;
            justify-content: flex-end;   
        }

        &__pages {
            font-style: italic;
            color: $color-primary;

            @include bp-down(xs) {
                font-size: .8rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

        }
    }

}