.live-page-video {
    color: #fff;

    .btn-translate {

        color: $color-blue-dark !important;

        @include bp-down(m) {
            height: auto;
        }
    }

    &-wrapper {
        @include bp-down(s){
            margin-top: 2rem;
        }
    }

    &-bg {
        position: relative;

        &-image {
            position: absolute;
            width: 180%;
            max-width: 140rem;
            left: -35%;
            top: -15vw;

            @include bp-down(s) {
                top: -25vw;
            }
        }
    }

    &__subtitle {
        font-style: italic;
        margin-bottom: 2rem;
    }

    &__section--questions {
        //width: 40rem;
        margin-left: 1rem;
        margin-right: 1rem;
        flex: 1 1 auto !important;

        & .chat-wrapper {
            height: 4rem;
        }

        @include bp-down(l) {
            margin: 0;
            width: 100%;
            order: -1;
            margin-bottom: 2rem;

            & .chat-wrapper {
                margin: 0;
            }
        }

    }

    &__section--polls {
        .btn-like {
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            cursor: pointer;

            &.released {
                animation: pulse 0.3s;
            }
        }
        .btn-like:active {
            border: 1px solid rgba(#000, 0);
            box-shadow: 0px 0px 15px 40px rgba(#fff, 0);
            text-decoration: none;
            padding: 2px;
        }
    }
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(#fff, 0.7);
        }

        70% {
            box-shadow: 0 0 0 10px rgba(#fff, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(#fff, 0);
        }
    }

    &__section-title {
        h2 {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }
        hr {
            border-top: 0.2rem solid #fff;
            margin-top: 0;
            width: 4rem;
        }
    }

    &__frame {
        border: none;
        background-color: $color-blue-dark;
    }
    &__interprefy {
        width: 300px;
        height: 360px; // min-height to see correctly the combobox
        position: absolute;
        z-index: 2;

        @include bp-down(m) {
            position: relative;
        }

        &-buttons {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: .5rem;

            & .btn-interprefy {
                width: 90px;
                height: 25px;
                border-radius: 18px;
                color: #fff;
                font-size: 0.8rem;
                font-weight: bold;
                box-shadow: none;
                border: none;
                outline: none !important;
                background-color: #ff5e21;
                margin-top: 1em;
            }
        }

        &-width {
            width: 300px;
        }

        & iframe {
            width: 100%;
            height: 100%;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        & .btn-close {
            position: absolute;
            width: 90px;
            top: 8px;
            right: 55px;
            height: 25px;
            border-radius: 18px;
            color: #fff;
            font-size: 0.8rem;
            font-weight: bold;
            box-shadow: none;
            border: none;
            outline: none !important;
            background-color: #ff5e21;
        }
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        margin: 0;
        margin-top: 2rem;

        @include bp-down(m) {
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            & > div:not(:first-child) {
                margin-top: 2rem;
            }
        }
    }

    & .live-page-games {
        background-color: $color-blue-light;
        color: $color-blue-dark;

        & .live-page-documents-item {
            border: none;
        }
    }
}
