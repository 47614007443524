.poll-chart-ring {

    align-items: center;

    &-main {

    }

    &-legend {

        &-items {

            padding-left: 2rem;

            @include bp-down(s){
                padding-left: 0;
            }

            &__title {
                margin-bottom: 1.5rem;
                color: $color-primary;
                font-weight: bold;

                @include bp-down(m){
                    margin-bottom: 1rem;
                }
            }

            &__item {

                display: flex;
                align-items: center;

                @include bp-down(m){

                }

                &-chart {
                    height: 5rem;
                    width: 5rem;
                    padding: .3rem;

                    @include bp-down(m){
                        height: 4rem;
                        width: 4rem;
                    }

                }

                & span {

                    margin-left: 1rem;
                    // Used to avoid problems on chart icon when text goes on multiple lines
                    max-width: 78%;

                    @include bp-down(m){
                        margin-left: .5rem;
                    }
                }
            }
        }
    }

}