
// $height-doughnut-answer: 60px;
// $width-doughnut-answer: 60px;
$height-doughnut-answer: 100%;
$width-doughnut-answer: 100%;
$cutoutPercentage-answer: 70;

.doughnut {

    &-border-out {
        height: $height-doughnut-answer;
        width: $width-doughnut-answer;
        position: relative;
        border: 1px solid $color-primary;
        border-radius: 50%;

        & #myChartQ1 {
            position: absolute;
            top: -28px;
            left: -14px;
            width: 96px !important;
            height: 96px !important;

            @include bp-down(m){
                top: -31px;
                left: -16px;
                width: 84px !important;
                height: 84px !important;
            }
        }
    }

    &-border-in {
        height: $height-doughnut-answer*$cutoutPercentage-answer/100;
        width: $width-doughnut-answer*$cutoutPercentage-answer/100;
        position: absolute;
        top: $height-doughnut-answer/2 - $height-doughnut-answer/2*$cutoutPercentage-answer/100;
        left: $width-doughnut-answer/2 - $width-doughnut-answer/2*$cutoutPercentage-answer/100;
        border: 1px solid $color-primary;
        border-radius: 50%;
        text-align: center;
        padding-top: .6rem;
        font-size: .9rem;

        @include bp-down(m){
            padding-top: .4rem;
            font-size: .7rem;
        }
    }
}


.poll-admin-live:fullscreen {
    & .doughnut {
        &-border-out {
            & #myChartQ1 {
                top: -27px;
                left: -13px;
                width: 111px !important;
                height: 112px !important;
            }
        }
    }
}