.live-page-header {

    padding: 1rem;
    display: flex;

    @include bp-down(s){
        padding: 0rem;
    }

    &-title {
        font-weight: bold;
        color: #fff;
    }

    &-logo-wrapper {
        text-align: center;
        padding-top: 1rem;
        margin-bottom: 2rem;

        @include bp-down(xxs){
            margin-bottom: 1rem;
        }
    }

    &-logo {
        width: 16rem;

        @include bp-down(s){
            width: 14rem;
        }

        @include bp-down(xxs){
            width: 12rem;
        }
    }

    &-image-wrapper {
        width: 9rem;
        margin-right: 1rem;
        margin-top: 0rem;

        @include bp-down(m){
            width: 8rem;
        }

        @include bp-down(xs){
            width: 7rem;
        }

        @media (max-width: 460px) {
            width: 6rem;
        }

        @include bp-down(xxs){
            width: 5rem;
        }

    }

    &-text-wrapper {

        & h1 {
            position: relative;
            font-family: 'Electra LT Std Display', Times, 'Times New Roman', serif;
            font-size: 3rem;
            line-height: 3rem;
            padding: .5rem;
            padding-right: 1.2rem;
            color: inherit !important;
            background-color: rgba(255,255,255,.8);

            @include bp-down(l){
                font-size: 2.8rem;
                line-height: 2.8rem;
            }

            @include bp-down(m){
                font-size: 2.4rem;
                line-height: 2.4rem;
            }

            @include bp-down(xs){
                font-size: 2.2rem;
                line-height: 2.2rem;
            }

            @media (max-width: 460px) {
                font-size: 1.7rem;
                line-height: 1.7rem;
            }

            @include bp-down(xxs){
                font-size: 1.6rem;
                line-height: 1.6rem;
            }

            & span.dot {
                position: absolute;
                bottom: 1.4rem;
                margin-left: -0.15rem;
                font-size: 5rem;
                color: $color-red;

                @include bp-down(l){
                    bottom: 1.3rem;
                }

                @include bp-down(m){
                    font-size: 4rem;
                    bottom: 1.35rem;
                }

                @include bp-down(xs){
                    font-size: 3.5rem;
                }

                @include bp-down(xxs){
                    font-size: 3rem;
                    bottom: 1rem;
                }
            }
        }

        & h2 {
            font-family: 'Gotham', Times, 'Times New Roman', serif;
            font-size: 1rem;
            text-align: left;
            color: #fff;
            text-transform: uppercase;

            @include bp-down(s){
                font-size: .9rem;
            }

            @include bp-down(xxs){
                font-size: .8rem;
            }
        }

        &.mobile {

            & h1 {
                background-color: transparent;
                padding: 0;
            }

            & span.text-line-wrapper {
                display: inline-block;
                background-color: rgba(255,255,255,.8);
                padding: .5rem;
                padding-bottom: .3rem;
                margin-bottom: .6rem;

                &--2 {
                    padding-right: 1.2rem;
                }

                @include bp-down(xxs){
                    padding: .4rem;
                    padding-bottom: .2rem;
                    margin-bottom: .4rem;

                    &--2 {
                        padding-right: .6rem;
                    }
                }
            }

        }

    }


    &-countdown {

        display: inline-block;

        &-wrapper {
            margin-top: 1rem;
            padding-right: 0;
            text-align: center;

            @include bp-down(s){
                justify-content: center;
                padding-right: 0;
            }
        }

        &-title {
            text-transform: uppercase;
            margin-bottom: .2rem;
            font-size: 1.4rem;
            color: #fff;

            @include bp-down(s){
                font-size: 1.2rem;
            }

            @include bp-down(xxs){
                font-size: 1rem;
            }
        }

        &-img {
            width: 24rem;

            @include bp-down(s){
                width: 18rem;
            }

            @include bp-down(xxs){
                width: 16rem;
            }
        }

    }

}