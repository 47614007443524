.poll-type {

    &-title {
        font-size: .8rem;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &-content {
        display: flex;
        position: relative;
    }

    &-live {
        margin-right: 1rem;
    }

    &-planned {
        display: flex;

        &-details {
            display: none;

            &.active {
                display: block;
            }
        }

        &-box {
            font-size: .8rem;
            font-weight: bold;
            display: inline-block;
            padding: .1rem .5rem;
            border: .1rem solid;
            border-image: linear-gradient(to right, $color-primary, rgba($color-primary, 0.4));
            border-image-slice: 1;

            &.choose-date {
                width: 12rem;
                margin-right: .5rem;
            }
        }

        &-date {
            border: none;
            padding: 0;
            margin: 0;
        }

        &-start, &-end {
            display: flex;
        }

        &-start {
            margin-bottom: .2rem;
        }
    }

    &-confirm {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}