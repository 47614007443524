// Custom colors definitions:
$blue:    #0081c9 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

// Zbin colors:
// -- primary
$zb-darkblue:   #2b2a80;
$zb-blue:       #0081c9;
$zb-teal:       #005b79;
$zb-lightteal:  #00a79d;
$zb-grey:       #474c55;
// -- secondary
$zb-purple:     #662d91;
$zb-magenta:    #b41e8e;
$zb-green:      #006738;
$zb-lightgreen: #89bd3d;
$zb-orange:     #fcaf17;
$zb-yellow:     #ffd540;
$zb-darkgreen:  #003d44;        // Used for On Air Surgery page
$zb-red:        #df0818;        // Used for On Air Surgery page

// font
$font-primary: 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;


:export {
    colorDarkblue100:   rgba($zb-darkblue,1);
    colorDarkblue75:   rgba($zb-darkblue,.75);
    colorDarkblue50:   rgba($zb-darkblue,.5);
    colorBlue100:   rgba($zb-blue,1);
    colorBlue75:   rgba($zb-blue,.75);
    colorBlue50:   rgba($zb-blue,.5);
    colorTeal100:   rgba($zb-teal,1);
    colorTeal75:   rgba($zb-teal,.75);
    colorTeal50:   rgba($zb-teal,.5);
    colorPurple100:   rgba($zb-purple,1);
    colorPurple75:   rgba($zb-purple,.75);
    colorPurple50:   rgba($zb-purple,.5);
    colorGreen100:   rgba($zb-green,1);
    colorGreen75:   rgba($zb-green,.75);
    colorGreen50:   rgba($zb-green,.5);
    colorMagenta100:   rgba($zb-magenta,1);
    colorMagenta75:   rgba($zb-magenta,.75);
    colorMagenta50:   rgba($zb-magenta,.5);
    colorOrange100:   rgba($zb-orange,1);
    colorOrange75:   rgba($zb-orange,.75);
    colorOrange50:   rgba($zb-orange,.5);
}
