.event-warning {

    &-content {
        display: flex;
        align-items: center;      
        justify-content: center;
        margin: 2rem;
    }


    &-wrapper {
        background-color: rgba($color-blue-light, 0.7);
        padding: 1rem;
        max-width: 70rem;
        margin-bottom: 2rem;
        position: relative;

        @include bp-down(s){
            max-width: 90%;
            padding: 0.6rem;
        }
        
    }

    &-close {
        position: absolute;
        right: 2rem;
        top: 1rem;
        font-weight: bold;
        font-size: 2rem;

        @include bp-down(s){
            font-size: 1rem;
        }

        &__icon {
            width: 3rem;
            cursor: pointer;

            @include bp-down(s){
                font-size: 1.5rem;
            }
        }
    }    
}

// .slider {
//     overflow-y: hidden;
//     max-height: 500px; /* approximate max height */
//     transition-property: all;
//     transition-duration: .5s;
//     transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
// }

// .slider.closed {
// 	max-height: 0;
//     padding: 0;
// }

.fadein, .fadeout {
    opacity: 0;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    
}
.fadein {
    opacity: 1;
}

.fadeout {
    opacity: 0;
}