.poll-answer {

    margin: .4rem 0;

    &-content {

        display: flex;
        align-items: center;
    }

    &-image {
        margin-top: .2rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: flex-end;

        &-new {
            display: flex;
            align-items: center;
            cursor: pointer;

            & svg {
                color: $color-primary;
            }

            & span {
                margin-left: .5rem;
            }
        }

        & img {
            max-width: 15rem;
            max-height: 10rem;
        }

        & label {
            margin: 0;

            & svg {
                display: flex;
            }
        }
    }

    &-select {
        height: 1.2rem !important;
        width: 1.2rem !important;
        margin-right: .5rem;

        @include bp-down(s){
            height: 1rem !important;
            width: 1rem !important;
        }

        & path {
            fill: $color-primary;
        }
    }

    &-icon {
        margin-left: .5rem;
    }

}