.modal-team-details {

    & .modal-content {
        background-color: #000;
        border: 3px solid $color-primary;
    }

    &___close-wrapper {
        margin-bottom: -2rem;
    }

    &__close {
        background-color: $color-primary;
        color: #fff;
        width: 2rem;
        min-width: 2.5rem !important;
        font-size: 1.4rem !important;
        border-radius: 10px !important;

        @include bp-down(s){
            width: 1.6rem;
            min-width: 2rem !important;
            font-size: 1rem !important;
        }

        @include bp-down(xxs){
            width: 1.4rem;
            min-width: 1.4rem !important;
            font-size: .9rem !important;
        }
    }

    &__logo {
        width: 40rem;
        margin-bottom: 4rem;

        @include bp-down(s){
            margin-bottom: 3rem;
        }
    }

    &__ball {
        width: 5rem;

        @include bp-down(s){
            width: 4rem;
        }

        @include bp-down(xxs){
            width: 3rem;
        }        
    }

    &__title {
        text-transform: uppercase;
        color: $color-primary;
    }

    &__separator {
        width: 100px;
        height: 1px;
        background-color: $color-secondary;
        margin: 0 auto;
    }

    &__subtitle {
        color: $color-secondary;
    }

    &__text {
        color: $color-secondary;
    }

    & .main-team-coach-image-wrapper {
        position: relative;
        overflow: hidden;
        border: .2rem solid blue;
        border-radius: 10px;
        margin: 0 auto;
        width: 10rem;
        min-width: 10rem;
    
        @include bp-down(l){
            width: 9rem;
            min-width: 9rem;
        }
    
        @include bp-down(xxs){
            width: 8rem;
            min-width: 8rem;
        }
    }
    & .main-team-coach-name {
        position: absolute;
        bottom: -2px;
        left: -2px;
        width: calc(100% + 4px);
        margin-bottom: 0;
        padding: 4px;
        padding-top: 2px;
        font-size: .9rem;
        line-height: 1rem;
        text-align: center;
        text-transform: uppercase;
        background-color: #fff;
        color: blue;
    }

}