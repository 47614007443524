.poll-intro {

    &-header {
        width: 100%;
        background-color: $color-primary-dark-30;
        display: flex;
        justify-content: space-between;

        &__new {
            border: .1rem solid #fff;
            width: 12rem; 
            margin: .5rem;

            @include bp-down(xs){
                width: 10rem;
            }
        }

        &__polls {
            color: #fff;
            font-weight: bold;
            &:hover, &:active, &:focus {
                color: #fff;
                outline: none;
                box-shadow: none;
            }
        }
    }

    &-discover {
        border: .1rem solid #fff;
        width: 12rem;

        @include bp-down(xs){
            width: 10rem;
        }
    }
}