.event-admin-modal {

    & .modal-title {
        font-weight: bold;
        color: $color-primary;
    }

    & .modal-body {
        & .notify-name {
            font-style: italic;
        }
    }

    & .buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        &__save {
            color: #fff;
            background-color: $color-green;
            border-color: $color-green;
        }
    }

    & .event-edit-form {

        & label {
            margin-bottom: .2rem;
        }

        & .datetime-picker {
            & svg {
                position: absolute;
                width: 1.2rem;
                height: 1.2rem;
                top: .5rem;
                right: .5rem;
            }
        }
    
        & input::placeholder {
            color: #bbb;
        }
    
        .react-datepicker {
            &-wrapper {
                width: 100%;
            }
        
            &__input-container {
        
                & input {
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 0.75rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }
        }
    }

    
}

label.required {
    
    &:after {
        content:" *";
        color: red;
    }
}
