.poll-new-question {

    padding-bottom: 1rem;
    border-bottom: .1rem solid $color-primary;
    margin-bottom: 1rem;

    &-title {
        
        &-name {
            @include bg-gradient($color-primary);
            font-weight: bold;
            color: #fff;
            margin-bottom: .5rem;
        }
    }

    &-image {
        margin-top: .2rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: flex-end;

        &-new {
            display: flex;
            align-items: center;
            cursor: pointer;

            & svg {
                color: $color-primary;
            }

            & span {
                margin-left: .5rem;
            }
        }

        & img {
            max-width: 15rem;
            max-height: 10rem;
        }

        & label {
            margin: 0;

            & svg {
                display: flex;
            }
        }
    }

    &-answers {

        &__correct {
            font-weight: bold;
            line-height: 1;
            margin-bottom: .5rem;

            &.error {
                color: $red;
            }
        }

        &__list {
            // margin-left: .5rem;  // used for checks
        }
    }

    &-save {
        text-align: right;
    }

    &-content {
        overflow: hidden;
        max-height: 100rem;     // Used for collapse animation

        &.closed {
            max-height: 0;
        }

        transition: max-height 0.4s linear;
    }
}