.poll-chart-histogram {

    max-width: 95%;
    margin: 0 auto;

    @include bp-down(s){
        flex-direction: column-reverse;
    }

    &-main {

    }

    &-legend {

        margin-top: 1rem;

        @include bp-down(s){
            margin-top: 0rem;
            margin-bottom: 1rem;
        }

        &-items {

            padding-left: 2rem;

            @include bp-down(m){
                padding-left: 1rem;
            }

            @include bp-down(s){
                padding-left: 0;
            }

            &__title {
                margin-bottom: 1.5rem;
                color: $color-primary;
                font-weight: bold;

                @include bp-down(m){
                    margin-bottom: 1rem;
                }
            }

            &__item {

                display: flex;
                align-items: center;
                margin-bottom: 1rem;

                @include bp-down(m){
                    margin-bottom: .5rem;
                }

                &-value {
                    width: 2.5rem;
                    text-align: center;
                }

                &-chart {
                    height: 1.5rem;
                    width: 2.5rem;

                    @include bp-down(m){
                        height: 1rem;
                        width: 2rem;
                    }
                }

                &-text {
                    margin-left: 1rem;
                    // Used to avoid problems on chart icon when text goes on multiple lines
                    max-width: 78%;

                    @include bp-down(m){
                        margin-left: .5rem;
                    }
                }
            }
        }
    }
}