.poll-question-item {
    display: flex;
    justify-content: space-between;
    color: #222;
    border-top: 0;
    padding: .4rem 1rem;
    background-color: transparent;

    &__title {

        @include bp-down(xs){
            max-width: 75%;
        }

        & svg {
            width: 2rem !important;
            margin-right: .5rem;
            color: $color-primary-dark-30;

            @include bp-down(xs){
                width: 1rem !important;
            }
        }

        & span {
            font-weight: bold;
        }
    }

    &__icons {

        width: 6rem;
        text-align: right;

        & svg {
            color: $color-primary-dark-30;
        }

        &--order {
            margin-right: .5rem;
        }

        &--trash {
            margin-left: .5rem;
        }
    }

    &__body {
        @include bp-down(s){
            padding: 0;
        }
    }
}