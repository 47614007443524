.live-page-section-header {

    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 0 1rem;

    &__icon {
        height: 3rem;
        margin-bottom: .5rem;

        @include bp-down(xxs){
            height: 2.5rem;
        }
    }

    &__title {
        font-weight: bold;
        text-align: center;
    }

    &__separator {
        border-top: .2rem solid #fff;
        margin-top: 0;
        width: 6rem;
    }

}