/* ==========================================================
	CHAT
========================================================== */

/*

Altezza elementi pagina lg CON BANNER:

nav: 54px
.benvenuto: 26px
.container (banner): 10px (padding-top)
img (banner senza .col): 280.8px
img (bannercon .col): 273.6px
.chat-outer: 30px (margin-top)
.user-panel: 110px (10px di margin-top)

somma
510.8px
503.6px

(Non sembrano coincidere perfettamente comunque)

Altezza elementi pagina lg SENZA BANNER:

nav: 54px
.benvenuto: 26px
.container (banner): 10px (padding-top)
.chat-outer: 30px (margin-top)
.user-panel: 130px (10px di margin-top) // Cambiato

somma
260px

------------

Colori:

color: #0081c9; // azzurro
color: #2b2a80; // blu
color: #5a5a5a; // grigio scuro
color: #ebebeb; // grigio chiaro

*/

html, body {
    height: 100%;
}

/***** To override padding-bottom applied to body in carousel.css and global.css *****/
body {
	padding-bottom: 0 !important;
}

/***** Preloader chat *****/
.preloader {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: url(/assets/images/loader-ball.png) center no-repeat #fff;
}

/***** Helper classes chat *****/
.margin-top-10 { margin-top: 10px; }
.margin-bottom-10 { margin-bottom: 10px; }


/* To hide menu .col with pending users to approve and set width of .col with username to 100% */
.benvenuto .col-sm-4:first-child, .benvenuto .col-sm-4:last-child {
	display: none;
}
.benvenuto .col-sm-4:nth-child(2) {
	width: 100%;
}

/* Se utilizzo chiamare in modo sensato - attenzione, in uso due volte in chat.php */
.chat-height-100 {
	height: 100%;
}

.chat-outer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	
	// height: auto; //calc(100% - 80px);
	// Setting fixed height for large screen
	height: 150vh;

	@include bp-down(m) {
		max-height: 90vh;
	}

	@include bp-down(xs) {
		max-height: 80vh;
	}

	@include bp-down(xxs) {
		max-height: 75vh;
	}
}

/* =================================
	CHAT - Chat closed
================================= */
.chat-opening-message.hidden { display: none; }
.chat-opening-message > span { display: block; }
.chat-opening-message .title { font-weight: 700 }

.chat-opening-message.error { 
	border-color: red; 
	margin: 3rem 0 6rem;
}

.problem-container .title,
.problem-container .description strong { color: $color-primary; }
.problem-container .title { font-size: 24px; display: block;}
.problem-container .description { font-size: 16px;}

.chat-closed-content {
	margin: 3rem 0 6rem;
}

/* =================================
	CHAT - Opening message
================================= */

.chat-opening-message-wrapper {
	position: relative;
	/*background: red;*/
}

.chat-opening-message {
	/*margin: 10px 0;*/
	margin-top: 10px;
	padding: 10px;
	border: 3px solid #ddd;
	border-radius: 10px;
	display: block;
}

.chat-om-close {
	outline: 0;
	color: $color-primary;
	opacity: 1;
}

.chat-om-close span {
	font-size: 30px;
}

#btn-chat-info {
	margin-right: 10px;
}

.chat-om-faculty-div {
	max-width: 120px;
	margin-right: 10px;
	/*margin-top: 15px;*/
	float: left;
	text-align: center;
	/*clear: both;*/
}

.chat-om-faculty-div p {
	margin: 4px 10px 10px;
	font-size: 10px;
	color: #000;
	font-weight: 700;
}

.chat-opening-message img {
	max-height: 65px;
	border-radius: 50%;
	border: 3px solid $color-primary;
}

.chat-om-text-div h1 {
	margin-top: 10px;
	font-weight: 700;
	font-size: 15px;
	color: $color-secondary;
}

.chat-om-text-div p {
	font-size: 13px;
	color: #999;
}

.chat-om-open {
	position: absolute;
	z-index: 10;
	display: none;
	bottom: -31px;
	right: 0;
	width: 34px;
	height: 31px;
	cursor: pointer;
	font-size: 18px;
	background-color: #fff;
	border: 3px solid $color-primary;
	border-top: 0;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	/*text-align: center;*/
	padding-left: 6px;
	line-height: 38px;
	color: $color-primary;
}

/*.chat-om-open span {

}*/

.chat-om-open:hover, .chat-om-open:active, .chat-om-open:focus {
	color: #999;
	border-color: #999;
}

/* =================================
	CHAT - Scrollable Modal info
================================= */

#chatInfoPopup .modal-body-scrollable {
    max-height: calc(100vh - 212px);
    overflow-y: auto;
}

#chatInfoPopup h2,
#chatInfoPopup h3 { color: $color-primary; margin-top: 30px; }
#chatInfoPopup a { font-weight: bold; }
#chatInfoPopup #do-donts > li { margin-top: 20px; }


/* =================================
	CHAT - Order option
================================= */

.chat-order-controls {

	padding: .6rem .3rem;

	& .form-check-label {
		color: $color-secondary;
		padding: 0 2rem;
		padding-left: .2rem;
	}
}


/* =================================
	CHAT - Message board
================================= */

.chat-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	position: relative;
	//min-height: 60vh;
	
	-webkit-box-flex: 1 1 auto !important;
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
	
	margin-top: 10px;
	overflow: hidden;

	&.backstage {
		// On small screen, when chat live and backstage are not adiacent, remove block not needed
		@include bp-down(s){
			.chat-order-controls, .user-panel-anonymous {
				display: none;
			}
		}
	}

}

.chat-wrapper .no-new-messages {
	position: absolute;
	left: calc(50% - 8rem);;
	top: 0;
	height: 2.2rem;
	width: 16rem;
	max-width: 16rem;
	text-align: center;
	line-height: 2.2rem;
	margin: 0 auto;
	background-color: $color-primary;
	color: #fff;
	border-radius: 4px;
}

#message-box {
	-webkit-box-flex: 1 1 auto !important;
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
	position: relative;

	overflow: auto;
	padding: 10px;
	background-color: $color-blue-dark;
	border: 2px solid $color-blue;
	
	// min-height: 74rem;
	/*background-color: red;*/

	@include bp-down(m){
		min-height: 20rem;
	}

	@include bp-down(xxs){
		padding: 5px;
	}
}

#previous-message-box {
	// display: none;
}

.system-message {
	width: 100% !important;
}

.system_msg, .system_error {
	/*border-top: 1px solid #bbb;*/
	margin: 10px 0;
	padding: 5px 10px;
	background-color: #ddd;
	border-radius: 5px;
	font-size: 12px;
	color: #5a5a5a;
}

.btn-retry-connection {
	padding: 0;
	border: 0;
	font-weight: 700;
	font-size: 12px;
	text-decoration: underline;
}

/*.system_msg {
	color:#bbbbbb;
}*/


/* =================================
	CHAT - Messages
================================= */

.message-wrapper {
	position: relative;
	width: 70%;
    padding-top: 2%;
}


// -- Header: time, userName, buttons

.message-wrapper .message-header {
    display: flex;
    justify-content: space-between;
	align-items: flex-start;
	
	& .message-buttons-wrapper {
		display: flex;
		flex-direction: row-reverse;
	}

	& .message-info-wrapper {

		& .msg-date, .msg-user {
			margin-bottom: 0;
			font-size: 10px; 
			color: $color-blue-dark; 
		}

		& .msg-user {
			font-weight: bold;
			font-size: 12px; 
		}

	}

}

.message-wrapper.own-message {

	& .message-info-wrapper {

		& .msg-date, .msg-user {
			color: #fff; 
		}

	}
}


.message-wrapper.own-message {
	margin-left: 30%;
}

.message-wrapper .btn {
	min-width: 4rem;

	&.btn-delete {
		min-width: 2rem;
	}
}

.chat-user-circle {
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 3px solid #ebebeb;
	background-color: #ebebeb;
	text-transform: uppercase;
}

.chat-user-circle.moderator {
	border: 3px solid $color-primary;
	background-color: $color-primary;
}

.chat-user-circle-faculty-img {
	border-radius: 50%;
}

.chat-user-circle .chat-user-circle-faculty-img + span {
	display: none;
}

/***** Se inutile non utilizzare span *****/
.chat-user-circle span {
	display: block;
	width: 54px;
	height: 54px;
	text-align: center;
	font-size: 26px;
	line-height: 50px;
	color: #5a5a5a;
}

.chat-user-circle.moderator span {
	color: #fff;
}

.message-wrapper.own-message .chat-user-circle {
	right: 0;
}

.message-wrapper > .message {
	width: 100%;
	margin-left: 5px;
	padding: 5px 8px;
	background-color: $color-blue-light;
	font-size: 13px;
	margin-bottom: 7px;
}



.hr_message {
	background-color: $color-blue-dark;
	margin: 0;
	margin-bottom: 1em;
}

.msg-date { font-size: 10px; color: $color-blue-dark; }

/* == QUOTED MESSAGES == */
.quoted-message-wrapper {
	font-size: 11px;
	margin-bottom: 6px;
	cursor: pointer;
}

.quoted-message-wrapper > .quoted-message {
	width: 100%;
	padding: 5px 8px;
	border-radius: 6px;
	background-color: $color-blue;
	font-style: italic;
	color: #fff;

	& .message {
		color: #fff;
	}
}

.own-message .quoted-message {
	background-color: $color-blue-dark !important;
	color: #fff;

	& .message {
		color: #fff;
	}
}

.quoted-message-wrapper > .quoted-message .author {
	font-weight: 700;
}

.quoted-message-wrapper > .quoted-message .message {
	font-style: normal;
	display: block;
}

#msg-quoted-container {
	display: block;
	position: relative;
	background-color: $color-primary;
	color: #FFF;
	font-size: 12px;
	padding: 5px;
	border-radius: 5px 5px 0 0;
}

#msg-quoted-container .btn-delete-quote {
	position: absolute;
	right: 5px;
	top: 5px;
	font-size: 10px;
	padding: 0 5px;
	background-color: #ebebeb;
	color: #5a5a5a;
	font-weight: 700;
}

#msg-quoted-container #msg-quoted-user-name {
	font-weight: 700;
}

#msg-quoted-container .msg-quoted-message {
	display: block;
	font-style: italic;
}

/* == Message attachments == */
.message > .chat-attachment { 
	/*position: relative; 
	width: 90%; 
	margin: 0 0 10px auto;*/
	margin-bottom: 6px;
	padding: 10px;
	background-color: #ddd;
	border-radius: 5px;
	color: #5a5a5a;
	cursor: pointer;
}

.own-message .chat-attachment {
	background-color: $color-blue-dark;
	color: #fff;
}

.message > .chat-attachment .chat-attachment-file {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.message > .chat-attachment .chat-attachment-data {
	display: inline-block;
	vertical-align: middle;
	font-size: 12px;
}

.message .chat-attachment .chat-attachment-file-name { font-weight: 700; }

img.attachment-preview {
	width: 40px;
}

img.attachment-preview.image-attachment {
	width: 100%;
}

/* == Message and current user message different styles == */
.message.own-message {
	margin-left: 0;
	background-color: $color-blue;
}

.message a:link, .message a:visited {
	color: $color-primary;
	text-decoration: underline;
}

.message a:hover, .message a:active, .message a:focus {
	color: #5a5a5a;
}

.message.own-message a:link, .message.own-message a:visited {
	color: #fff;
	text-decoration: underline;
}

.message.own-message a:hover, .message.own-message a:active, .message.own-message a:focus {
	color: #ccc;
}

.user_name {
	/*margin-top: -100px;*/
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 700;
	color: $color-primary;
}

.user_message {
	color: $color-blue-dark;
}

.message-wrapper .message {
	color: $color-blue-dark;
}

.message.own-message .user_name{
	/*margin-top: -100px;*/
	color:  #fff;
	display: none;
}

.message.own-message .msg-date{
	color:  #fff;
}

.message.own-message .hr_message{
	background-color:  #fff;
}

.message.own-message .user_message{
	color:  #fff;
}

/*.user_message {
	margin-top: -10px;
}*/

/* == ATTACHMENTS (textarea) == */
#msg-attachments-container {
	display: block;
	position: relative;
	background-color: $color-blue-dark;
	font-size: 10px;
	padding: 5px;
	padding-right: 40px;
	border-radius: 0 0 5px 5px;
	margin-top: 10px;
}

#msg-attachments-container .file-attachment {
	display: inline-block;
	margin: 3px;
	border-radius: 5px;
	background-color: #0081c9;
	color: #fff;
	position: relative;
	padding: 5px;
}
#msg-attachments-container .file-attachment .btn-delete-attachment {
	font-size: 10px;
	padding: 0 5px;
	background-color: #ebebeb;
	color: #5a5a5a;
	font-weight: 700;
}
#msg-attachments-container .file-attachment > span {
	display: inline-block;
	vertical-align: middle;
}

#msg-attachments-container #delete-current-attachments {
	display: block;
	position: absolute;
	right: 5px;
	top: 8px;
	padding: 3px 10px;
}

#msg-attachments-container .file-attachment .file-icon {
	width: 20px;
	height: 20px;
	margin: 0 3px;
	background-position: center center;
	background-size: contain;

}

/* file-icon bg image defined by data-type attribute: */
#msg-attachments-container .file-attachment .file-icon[data-type='application/pdf'] {
	background-image: url("/assets/icons/pdf.png");
}
#msg-attachments-container .file-attachment .file-icon[data-type^='image/'] {
	background-image: url("/assets/icons/default.png");
}

#msg-attachments-container .file-attachment .file-icon[data-type^='video/'] {
	background-image: url("/assets/icons/video.png");
}

#msg-attachments-container .file-attachment .file-icon[data-type='application/vnd.ms-powerpoint'],
#msg-attachments-container .file-attachment .file-icon[data-type='application/vnd.openxmlformats-officedocument.presentationml.presentation'] {
	background-image: url("/assets/icons/ppt.png");
}

#msg-attachments-container .file-attachment .file-icon[data-type='application/vnd.ms-excel'],
#msg-attachments-container .file-attachment .file-icon[data-type='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] {
	background-image: url("/assets/icons/xls.png");
}

#msg-attachments-container .file-attachment .file-icon[data-type='application/msword'],
#msg-attachments-container .file-attachment .file-icon[data-type='application/vnd.ms-word'],
#msg-attachments-container .file-attachment .file-icon[data-type='application/vnd.openxmlformats-officedocument.wordprocessingml.document'] {
	background-image: url("/assets/icons/word.ico");
}

#msg-attachments-container .file-attachment > .file-size {
	margin-left: 3px;
	padding: 3px;
	font-weight: 700;
	background-color: $color-blue-dark;
}

/*** Per icone pdf, etc... ***/
.chat-wrapper a[id^='file-attachment'] img{
	width: auto;
	max-height: 40px;
}

/***** TEMP *****/
p.user_message span.location {
	font-size: 12px;
	font-style: italic;
	background-color: $color-blue-dark;
	padding: 2px 5px;
	color: #ddd;
}
p.user_message span.location.live {
	color: lawngreen;
}


/*** /Mettere in chat.js? ***/

.btn-like, .btn-quote, .btn-delete {
	color: $color-blue-light;
}

.btn-quote {
	color: $color-blue-light;
	height: 2.8em;
}

.btn.chat-message-id.btn-like:after {
	content: " ";
	position: absolute;
	top: 100%;
	right: 50%;
    margin-right: -3px;
	border-top: 6px solid $color-blue-dark;
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
	border-bottom: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

.own-message .btn.chat-message-id.btn-like:after {
	border-top: 6px solid #fff;
  }

  .btn.chat-message-id:hover.btn-like:after{
    border-top-color: $color-blue;
  }

  .message.own-message .btn.chat-message-id:hover.btn-like:after{
    border-top-color: $color-blue-dark;
  }

.btn.chat-message-id, .btn.chat-delete-message-btn, .btn.chat-ban-user-btn {
	position: relative;
	margin: 2px 4px;
	float: right;
	padding: 3px 6px;
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: $color-blue-dark;
	border-radius: 0% !important;

	@include bp-down(xs){
		margin: 1px 2px;
		padding: 2px 6px;
		font-size: 9px;
		min-width: 2rem;
	}

	&.selected {
		background-color: $color-blue;
		color: #fff;

		&.btn-like:after {
			border-top: 6px solid $color-blue;
		}
	}

}

.message.own-message .btn.chat-message-id, .message.own-message .btn.chat-delete-message-btn, .message.own-message .btn.chat-ban-user-btn {
	background-color: #fff;
	color: $color-blue;

	&.selected {
		background-color: $color-blue-dark;
		color: #fff;

		&.btn-like:after {
			border-top: 6px solid $color-blue-dark;
		}
	}
}

.btn.chat-message-id:hover, .btn.chat-delete-message-btn:hover, .btn.chat-ban-user-btn:hover,
.btn.chat-message-id:active, .btn.chat-delete-message-btn:active, .btn.chat-ban-user-btn:active,
.btn.chat-delete-message-btn:focus, .btn.chat-ban-user-btn:focus {
	background-color: $color-blue;
	color: #fff;
}


.message.own-message .btn.chat-message-id:hover, .message.own-message .btn.chat-delete-message-btn:hover, .message.own-message .btn.chat-ban-user-btn:hover,
.message.own-message .btn.chat-message-id:active, .message.own-message .btn.chat-delete-message-btn:active, .message.own-message .btn.chat-ban-user-btn:active,
.message.own-message .btn.chat-delete-message-btn:focus, .message.own-message .btn.chat-ban-user-btn:focus
 {
	background-color: $color-blue-dark;
	color: #fff;
}


// add to favorite / evidence / highlight message & delete message icon & archive message icon
.message .icon-favorite, .message .icon-delete, .message .icon-archive {
	float: right;
	// margin: 4px 8px;
    width: 1rem;
	height: 1rem;
	cursor: pointer;
}
.message .icon-delete {
	width: .8rem;
}

.message-wrapper.evidence {

	& .chat-user-circle {
		background-color: $color-primary;
		& span {
			color: #fff;
		}
	}

	& .message {
		background-color: $color-primary;
		color: #fff;

		& .user_name {
			color: #fff;
		}

		& .user_message {
			color: #fff;
		}

		& .message-buttons-wrapper button {
			background-color: $color-blue-dark;
			color: #fff;

			// &:focus, &:active, &:hover {
				// background-color: #ddd;
    			// color: #5a5a5a;
			// }
		}

		& .chat-attachment {
			background-color: $color-blue-dark;
    		color: #fff;
		}
	}

	& .message-badge {
		color: $color-primary;
		background-color: #fff;
	}

	& .btn.btn-like.chat-message-id:after {
		border-top: 6px solid $color-blue-dark;
	}
}

.message-buttons-wrapper {
	padding-bottom: .5rem;
}

// evidence new messages
.message-wrapper .new-message {
	position: absolute;
	bottom: 8px;
	right: 8px;
	margin-bottom: 0;
	color: #fff;
	background-color: $color-primary;
	padding: .1rem .3rem;
	border-radius: 6px;
}

// evidence message status (es. archived)
.message-wrapper .message-badge-wrapper {
	margin-bottom: .3rem;
}
.message-wrapper .message-badge {
	margin-bottom: 0;
	color: #fff;
	background-color: $color-primary;
	padding: .3rem .6rem;
	border-radius: 4px;
}

// show message full size
.message-wrapper.large {

	width: 100%;

	& .chat-user-circle {
		display: none;
	}

	& .message {
		margin-left: 0;
		width: 100%;

		& .user_name {
			font-size: 13px;
		}
	}
}


/* =================================
	CHAT - User panel
================================= */

.user-panel-wrapper {
	
	
	//background-color: $bg-color-box;

	@include bp-down(xs){
		padding: .6rem 1rem;
	}
}

.user-panel-wrapper h1, .user-panel-wrapper h2 {
	color: $color-text;
}

.user-panel {
	position: relative;
	/*min-height: 200px;*/
	padding-right: 5px;
	// width: 100%;

	&-wrapper {
		position: relative;
		margin-bottom: 1rem;
	}

	&-feedback {
		margin: 2px 10px;
		display: none;
		font-size: 90%;
		color: $color-green !important;
		position: absolute;
		top: 100%;

		&.active {
			display: block;
			animation: fadeOutFromBlock 3s ease-out;
			animation-delay: 1s;
		}
	}	
}

.user-panel__buttons {
	display: flex;
    justify-content: flex-end;
}

.user-panel .btn {
	min-width: 3rem;
}

.user-panel-anonymous {
	padding: .6rem .3rem;
	color: #fff;
}

#message-textarea {
	// width: calc(100% - 10vw);
	width: 100%;
	max-height: 500px;
	height: 41px;
	vertical-align: middle;
	padding: 10px;
	border: 2px solid $color-blue;
	background-color: #fff;
	// color: #fff;
	color: $color-blue-dark;
	font-size: .9rem;
	
	/***** Reset textarea default styles *****/
	overflow: auto;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	resize: none; /*remove the resize handle on the bottom right*/ /* ATTENZIONE, SE LO LASCIO COSI' DEFINIRE ALTEZZE CONSONE PER I VARI DISPOSITIVI */

	@include bp-down(s){
		font-size: .8rem;
	}
}


#inputWithIcon {
	position: relative;
}

#inputWithIcon textarea {
	padding-right: 40px;
  }

#inputWithIcon span {
	position: absolute;
	right: 0;
	padding-right: 1em;
	color: $color-blue-dark;
	transition: 0.3s;
  }

#inputWithIcon span:hover {
	color: $color-blue;
  }

  .icon-thumb {
	padding-left: 0.3em;
	padding-bottom: 0.2em;
	font-size: 20px;

	@include bp-down(xs) {
		font-size: 18px;
	}
  }

/***** Reset textarea default placeholder text styles *****/
#message-textarea::-webkit-input-placeholder {
	font-style: italic;
	font-size: .8rem;
	// color: #ccc;
	color: $color-grey-middle;
	white-space: nowrap;
	text-overflow:ellipsis;
}
#message-textarea:-moz-placeholder { /* Firefox 18- */
	font-style: italic;
	font-size: .8rem;
	// color: #ccc;
	color: $color-grey-middle;
	white-space: nowrap;
	text-overflow:ellipsis;
}
#message-textarea::-moz-placeholder {  /* Firefox 19+ */
	font-style: italic;
	font-size: .8rem;
	// color: #ccc;
	color: $color-grey-middle;
	white-space: nowrap;
	text-overflow:ellipsis;
}
#message-textarea:-ms-input-placeholder {
	font-style: italic;
	font-size: .8rem;
	// color: #ccc;
	color: $color-grey-middle;
	white-space: nowrap;
	text-overflow:ellipsis;
}
#message-textarea::placeholder {
	font-style: italic;
	font-size: .8rem;
	// color: #ccc;
	color: $color-grey-middle;
	white-space: nowrap;
	text-overflow: ellipsis !important;
	opacity: 1;
}
#message-textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
	// color: #ccc;
	color: $color-grey-middle;
}

#message-textarea::-ms-input-placeholder { /* Microsoft Edge */
	// color: #ccc;
	color: $color-grey-middle;
}

	
/***** /Reset textarea default placeholder text styles *****/

#chat-attachment-btn, #send-message {
	float: right;
	cursor: pointer;
	width: 40px;
	height: 40px;
	margin: 0;
	font-size: 23px;
	text-align: center;
	line-height: 40px;
	color: $color-secondary;
}

#chat-attachment-btn:hover, #send-message:hover {
	color: #5a5a5a;
}

/* #chat-attachment button */
.chat-wrapper .hidden {
	display: none;
}
.chat-wrapper {
	// margin-bottom: 30px;
}


@keyframes fadeOutFromBlock {
    0% {
        display: block;
        opacity: 1;
    }
    100% {
        display: none;
        opacity: 0;
    }
}


/* =================================
	CHAT - Media queries
================================= */

/***** md *****/
@media screen and (max-width: 1199px) {
	.message-wrapper {
		width: 85%;
	}

	.message-wrapper.own-message {
		margin-left: 15%;
	}
}  
/***** /md *****/

/***** sm *****/
@media screen and (max-width: 991px) {

	.chat-om-text-div h1 {
		font-size: 13px;
	}
	
	.chat-om-text-div p {
		font-size: 10px;
	}

	.message-wrapper {
		width: 70%;
	}

	.message-wrapper.own-message {
		margin-left: 30%;
	}
}
/***** /sm *****/

/***** xs *****/
@media screen and (max-width: 767px) {

	.chat-om-main-msg  {
		display: none;
	}

	.chat-om-open {
		display: block;
	}

	.message-wrapper {
		width: 80%;
	}

	.message-wrapper.own-message {
		margin-left: 20%;
	}

	.message-wrapper > .message {
		width: 100%;
	}

	.message.own-message {
		margin-left: 0;
	}

	.chat-user-circle {
		width: 40px;
		height: 40px;
	}

	.chat-user-circle span {
		width: 34px;
		height: 34px;
		font-size: 16px;
		line-height: 33px;
	}
}
/***** /xs *****/

@media screen and (max-width: 500px) {
	// #message-textarea {
	// 	width: calc(100% - 14vw);
	// }

	.message-wrapper {
		width: 90%;
	}

	.message-wrapper.own-message {
		margin-left: 10%;
	}
}


/* ==========================================================
	/CHAT
========================================================== */


/* SCROLL */
.scroll-top {
	position: absolute;
	bottom: .5rem;
	left: calc(50% - 1rem);
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #000;
	opacity: 0.2;
	color: #fff;
	cursor: pointer;
  
	@include bp-down(s) {
	  width: 2rem;
	  height: 2rem;
	}
  
	&:hover {
	  opacity: 1;
	  background-color: #ccc;
	}
}
