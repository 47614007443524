.event-countdown {

    display: inline-block;

    &-wrapper {
        margin-top: 1rem;
        padding-right: 0;
        text-align: center;

        @include bp-down(s){
            justify-content: center;
            padding-right: 0;
        }
    }

    &-title {
        text-transform: uppercase;
        color: #fff;
        margin-bottom: .2rem;
        font-size: 1.2rem;

        @include bp-down(s){
            font-size: 1.2rem;
        }

        @include bp-down(xxs){
            font-size: 1.1rem;
        }
    }

    &-img {
        width: 20rem;

        @include bp-down(s){
            width: 18rem;
        }

        @include bp-down(xxs){
            width: 16rem;
        }
    }

}