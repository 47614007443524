.event-header {

    color: #fff;
    text-align: center;

    &__title {
        // font-weight: bold;
        line-height: 1;
        text-align: center;

        &__words {
            padding-top: 1rem;
        }

        img {
            width: 14rem;
            height: 17rem;

            @include bp-down(m) {
                width: 10rem;
                height: 13rem;
            }
        }
    }

    &__subtitle {
        // font-style: italic;
        // margin-bottom: 1.5rem;
        color: $color-blue-dark;
        font-weight: bold;
    }

    &__date {
        // font-weight: 100;
        &-day {
            display: inline-block;
            background-color: $color-blue-dark;
            font-weight: bold;
            padding: .5rem 1rem;
            margin-bottom: .5rem;
        }
    }

    &__separator {
        width: 60px;
        height: 1px;
        background-color: #fff;
        margin: 2rem auto;

        @include bp-down(s){
            margin: 1.5rem auto;
        }

        @include bp-down(xxs){
            margin: 1rem auto;
        }
    }

}