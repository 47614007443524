.live-page-sessions {

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 3rem;
        margin-bottom: .5rem;

        @include bp-down(m){
            padding: 0;
        }
    }

    &__title {
        // color: $color-primary !important;
        margin-bottom: 0;
        
    }

    &-list {
        padding: 1rem 3rem;

        @include bp-down(m){
            padding: .8rem 0;
        }

        @include bp-down(xs){
            padding: .5rem 1rem;
        }
    }

    &-item {

        max-width: 28rem;
        margin: 0 auto;
        // padding: 1rem;
        // border: 1px solid $color-primary;
        height: 100%;
        cursor: pointer;

        &-wrapper {
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        &__link, &__link:hover {
            text-decoration: none;
        }

        &__title {
            color: $color-green !important;
            font-size: .9rem;
        }

        &__subtitle {
            color: #fff;
            min-height: 4rem;
            font-size: 1rem;

            & span {
                font-size: .8rem;
                font-style: italic;
                color: $color-primary;
            }
        }

        &__text {
            margin-top: 1rem;
            color: #fff;
            text-align: center;
            background-color: rgba($bg-color-box,.8);
            padding: .2rem .5rem;
        }
    }

    &-video {

        &-wrapper {
            margin-bottom: 2rem;
        }

        &__title {
            color: $color-green !important;
            font-size: 1.2rem;
        }

        &__back {

            @include bp-down(xxs){
                float: none !important;
                margin-left: auto;
            }
        }

        &__subtitle {

            font-size: 1.4rem;

            @include bp-down(xs){
                font-size: 1.2rem;
            }

            & span {
                font-size: 1.1rem;
                font-style: italic;
                color: $color-primary;

                @include bp-down(xs){
                    font-size: .9rem;
                }
            }
        }

        &__frame {
            border: 1px solid $color-primary;
        }
    }

    &-loader {

        &-wrapper {
            min-height: 20rem;
        }

        &-img {
            left: calc(50% - 50px);
            top: calc(50% - 50px);
            width: 100px;
            position: absolute;
            animation:spin 2s linear infinite;
        }
    }
}