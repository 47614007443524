.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $navbar-height;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.4);
    background-color: $color-blue-dark;
    // #IMPORTANT: set zIndex and position to show shadow over next element
    z-index: 999;
    position: relative;
    // #IMPORTANT: show nav while scrolling down
    top: 0;
    padding-left: 1rem;
    position: sticky;

    @include bp-down(s){
        flex-direction: column;
        align-items: flex-start;
        padding: .5rem;
        height: auto;
    }

    &__close, &__open {
        position: absolute;
        left: 0;
        width: 2rem !important;
        height: 2rem;
        padding: .4rem;
        cursor: pointer;
    }

    &-logo {
        width: 10rem;
    }

    &-profile {

        display: flex;
        align-items: center;

        @include bp-down(s){
            margin-bottom: .5rem;
        }

        &__img-wrapper {
            width: 2.4rem !important;
            height: 2.4rem;
            margin-right: 1rem;
            border-radius: 50%;
            overflow: hidden;
            // center image inside div
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__icon {
            width: 2.4rem !important;
            height: 2.4rem;
            padding: .4rem;
            margin-right: 1rem;
            color: $color-primary;
            background-color: $color-text;
            border-radius: 50%;
        }

        &__name {
            font-size: 1rem;
            margin-bottom: 0;
            color: #fff;
            line-height: 1.2;
        }
    }

    &-buttons {

        display: flex;
        align-items: center;

        @include bp-down(s){
            width: 100%;
            justify-content: space-between;
            flex-direction: column;
        }

        &-points {

            margin-right: 6rem;
            display: flex;
            align-items: center;

            @include bp-down(s){
                margin: 0;
            }

            &__icon {
                margin-right: 1rem;
                color: $color-primary;
                background-color: $color-text;
                width: 2rem !important;
                height: auto;
                padding: .3rem;
                border-radius: 50%;
            }

            &__text {
                font-weight: 700;
            }
        }

        &-sm-left {

            @include bp-down(s){
                width: 100%;
    
                & button {
                    width: 100%;
                }
            }
        }   

        &-sm-right {
            display: flex;
            align-items: center;

            &--1, &--2 {
                display: flex;
            }

            @include bp-down(s){
                width: 100%;
                margin-top: .5rem;

                &--1, &--2 {
                    width: 50%;
                }

                & button {
                    flex: 1 1 0;
                }
            }

            @include bp-down(xs){

                flex-direction: column;

                &--2 {
                    margin-top: .5rem;
                }

                &--1, &--2 {
                    width: 100%;
                }
            }
        }

        &__questions {
            margin-right: 2rem;
        }

        & .btn-edit-profile, & .btn-leaderboard, & .btn-gallery {
            margin-right: .5rem;
        }

        @include bp-down(xs){

            & .btn-leaderboard {
                margin-right: 0;
            }

        }

    }
}