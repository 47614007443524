.poll-type-chart {

    &-title {
        font-size: .8rem;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &-content {
        display: flex;
        position: relative;
    }

    &-ring {
        margin-right: 1rem;
    }

}