.terms-conditions {

    font-size: .8rem;

    @include bp-down(s) {
        font-size: .7rem;
    }

    & h1 {
        font-size: 1.3rem;

        @include bp-down(s) {
            font-size: 1.1rem;
        }
    }

    & h2 {
        font-size: 1.2rem;

        @include bp-down(s) {
            font-size: 1rem;
        }
    }

    & h4 {
        font-size: 1rem;

        @include bp-down(s) {
            font-size: .9rem;
        }
    }

    & .last-update {
        font-size: .9rem;

        @include bp-down(s) {
            font-size: .8rem;
        }
    }

}