@keyframes #{$rt-namespace}__trackProgress {
  0%{
    transform: scaleX(1);
  }
  100%{
    transform: scaleX(0);
  }
}

.#{$rt-namespace}__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: $rt-z-index;
  opacity: 0.7;
  background-color: rgba(255,255,255,.7);
  transform-origin: left;

  &--animated {
    animation: #{$rt-namespace}__trackProgress linear 1 forwards;
  }

  &--controlled {
    transition: transform .2s;
  }

  &--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
  }

  &--default{
    background: $rt-color-progress-default;
  }

  &--dark{
    background: $rt-color-progress-dark;
  }
  
  &--info{}
  &--success{}
  &--warning{}
  &--error{}
}
