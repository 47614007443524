button.btn {

    font-size: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6rem;    // PROBLEMA IE

    & .image-icon {
        width: 1.5rem;
    }

    &.icon {
        min-width: 1rem;
    }

    &.btn-sm {
        padding: 0.3rem 0.7rem;
        font-size: .9rem;
    }

    &.btn-lg {
        padding: 0.6rem 1.2rem;
        font-size: 1.1rem;
    }

    &.disabled {
        cursor: default;
    }

    @include bp-down(s) {
        font-size: .9rem;
        padding: 0.4rem 0.8rem;

        &.btn-sm {
            font-size: .81rem;
            padding: 0.25rem 0.6rem;
        }

        &.btn-lg {
            padding: 0.5rem 1rem;
            font-size: .99rem;
        }
    }

    // Setting button colors
    &.danger, &.red {
        color: #fff;
        background-color: $red;
        border-color: $red;
    }

    &.blue {
        color: #fff;
        background-color: $color-blue;
        border-color: $color-blue;
    }

    &.green-light {
        color: #fff;
        background-color: $color-green-light;
        border-color: $color-green-light;
    }

    &.confirm {
        color: #fff;
        background-color: $green;
        border-color: $green;
    }

    &.btn-primary {
        color: #fff;
        background-color: $bg-color-button;
        border-color: $bg-color-button;

        &:active {
            color: #fff;
            background-color: $bg-color-button !important;
            border-color: $bg-color-button !important;
        }
    }

    &.btn-secondary {
        color: $color-blue-dark;
        background-color: $color-blue-light;
        border-color: $color-blue-light;

        &:active {
            color: $color-blue-dark !important;
            background-color: $color-blue-light !important;
            border-color: $color-blue-light !important;
        }
    }

    &:focus {
        box-shadow: none !important;
    }

}
