.poll-item {

    border-radius: 0;
    margin: .5rem 0;

    &__header {
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: .5rem 1rem !important;
        padding-left: .5rem !important;

        &-title svg {
            margin-right: .5rem;
        }

        &-icons {
            color: $color-primary-dark-30;
            cursor: pointer;
            
            & svg {
                margin-left: 1rem;
            }
        }

        & .icon-wifi, & .icon-calendar {
            width: 1.5rem;
        }
    }

    &__body {
        padding: 0;
    }

}