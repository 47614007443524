.forgot-password {

    padding-bottom: 6rem;
    background-image: url('/assets/images/bg-page-new.jpg');
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;
    
    & .interactive-page-login-error {
    
        color: #fff;
        min-height: 18rem;
        max-width: 60rem;
        margin: 0 auto;
        background-color: rgba(0,0,0,.6);
        margin-bottom: 4rem;
    
        & h1 {
            margin-top: 6rem;
            margin-bottom: 3rem;
            color: $color-primary;
        }
    
        & h2 {
            margin-bottom: 3rem;
    
            & a {
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }

    & .interactive-page-form__submit {
        margin-bottom: 1rem;
    }

    & .invalid-feedback, & .valid-feedback {
        text-align: center;
    }

}