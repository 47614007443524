.page-not-found {
    text-align: center;
    padding: 6rem 1rem 8rem;
    background-image: url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;


    &__title {
        font-size: 10rem;
        color: $color-blue-dark;
        margin-bottom: 2rem;

        @include bp-down(s){
            font-size: 8rem;
        }

        @include bp-down(xxs){
            font-size: 6rem;
        }
    }

    &__subtitle {
        font-size: 4rem;
        color: $color-secondary;
        margin-bottom: 2rem;

        @include bp-down(s){
            font-size: 3rem;
        }

        @include bp-down(xxs){
            font-size: 2rem;
        }
    }

    &__descr {
        color: $color-secondary;
        margin-bottom: 2rem;
    }

}