.interactive-page-video-viewer-main {

    color: #fff;
    text-align: center;
    max-width: 60rem;
    margin: 0 auto;

    @include bp-down(xxs){
        padding: 0rem;
    }

    & h1 {
        margin-bottom: 2rem;
        color: $color-primary;
    }

    & a {
        color: $color-primary;
        outline: none;
    }

    & .container-video {
        margin-bottom: 4rem;
        border: 1px solid $color-primary;

        @include bp-down(xs){
            margin-bottom: 2rem;
        }
    }
}