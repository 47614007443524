.live-page {

    max-width: $max-page-width;
    margin: 0 auto;

    &-wrapper {
        position: relative;
        padding-bottom: 5rem;
        @include bg-page();
        overflow: hidden;

        @include bp-down(s){
            padding-bottom: 3rem;
        }

        @include bp-down(xxs){
            padding-bottom: 1rem;
        }
    }

    &-main {
        margin-top: 1.2rem;

        // @include bp-down(s){
        //     margin-top: 3rem;
        // }
    }


    // REGISTRATION ONLY - SECTIONS

    &-reg-header {
        //max-width: 60%;
        margin: 0 auto;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
    }

    &-reg-countdown {
        text-align: center;
        margin-bottom: 2rem;
    }

    &-reg-warning {
        text-align: center;
        margin-bottom: 2rem;
        
        &__button {
            font-weight: bold;
        }
    }

    &-reg-program {
        text-align: center;
        margin-bottom: 4rem;

        @include bp-down(xxs){
            margin-bottom: 3rem;
        }
    }

    &-reg-paragraph {
        color: #fff;
        margin-bottom: 3rem;

        &-separator {
            width: 60px;
            height: 1px;
            background-color: $color-secondary;
            margin: 1.5rem auto;
        }
    }

    &-reg-calendar {
        margin-bottom: 4rem;

        @include bp-down(xs){
            margin-bottom: 3rem;
        }
    }

    &-reg-documents {
        max-width: 80rem;
        margin: 0 auto;
    }



    &-barcode {
        padding: 1rem;
        margin: 0 auto;
    }

    &-question {
        &-title {
            color: $color-green !important;
        }
    }

    .page-title {
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        padding: .8rem;
        margin-bottom: .2rem;
    }

    &-poll {

        position: relative;
        
        &__title {
            color: $color-primary !important;
        }

        &__info {
            & span {
                color: $color-primary !important;
            }
        }
    }

    & .outer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        & .inner {
            width: 60%;
            font-size: 8vw;
            font-weight: bold;
            color: #fff;
            text-shadow: 2px 2px 5px #fff;
            transform:  rotate(-30deg);
            opacity: .4;

            &.agenda {
                width: 90%;
                font-size: 4rem;
                transform:  rotate(-45deg);
            }
        }
    }

    // -----------------------------------
    // TEMPLATE ONE - SECTIONS
    // -----------------------------------

    &-templ-one-video {

    }

    &-templ-one-chat {

        @include bp-down(s){
            margin-top: 2rem;
        }

        & .chat-outer {
            height: 40rem;
        }
    }

    &-templ-one-polls {

        @include bp-down(s){
            margin-top: 2rem;
        }

        & .live-page-poll-questions__frame {
            height: 35vw;
            max-height: 40rem;

            @include bp-down(s){
                height: 20rem;
            }
        }
    }

    &-templ-one-agenda {
        margin-top: 3rem;

        & .live-page-agenda {
            max-width: 22rem;
            margin: 0 auto;
        }
    }

    &-templ-one-docs {
        margin-top: 3rem;

        @include bp-down(xxs){
            margin-top: 2rem;
        }
    }


    // -----------------------------------
    // TEMPLATE THREE - SECTIONS
    // -----------------------------------

    &--template-three {
        margin-top: 4rem;
    }

    &-templ-three-chat {

        margin-top: 2rem;
        margin-bottom: 1rem;

        @include bp-down(xxs){
            margin-top: 1rem;
        }

        & .live-page-section-header__separator {
            margin-bottom: .5rem;
        }

        & .chat-wrapper {
            margin-top: 0;
        }
    }

    &-templ-three-polls {

        margin-bottom: 2rem;

        & .live-page-poll {
            margin-top: 0;
            max-height: 35rem;
            overflow: hidden;
            
        }

        & .live-page-poll-questions__frame {
            height: calc(10rem + 20vw);
            min-height: 20rem;
            max-height: 34rem;
        }
    }

    &-templ-three-group-two {

        margin-top: 3rem;
        @include bp-down(m){
            & div:first-child {
                order: 1
            }
        }
    }

    &-templ-three-agenda {
        margin-top: 0;
    }

    &-templ-three-docs {
        margin-top: 0;

        @include bp-down(m){
            margin-top: 2rem;
        }
    }

}
