.interactive-page-terms-text {

    color: #fff;
    text-align: center;
    padding: 4rem 1rem;
    max-width: 60rem;
    margin: 0 auto;

    @include bp-down(xs){
        padding: 2rem 1rem;
    }

    & h1 {
        margin-bottom: 2rem;
    }
}