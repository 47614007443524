.interactive-page-register {

    min-height: 80vh;
    padding-bottom: 4rem;
    background-image: url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;

    @include bp-down(s) {
        padding-bottom: 2rem;
    }

    @include bp-down(xxs) {
        padding-bottom: 0rem;
    }

    &-header {
        text-align: right;
        margin-top: 3rem;
        margin-right: 4rem;

        @include bp-down(s){
            text-align: center;
            margin-right: 0;
            margin-top: 2rem;
        }

        & h1 {
            margin-bottom: .2rem;
        }
    }

    &-tabs {

        margin-bottom: 2rem;

        &-update, &-register {
            display: flex;
            justify-content: center;
            align-items: center;

            & a {
                padding: 0 .2rem;
            }
        }

    }

    & .terms-conditions {
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
    }

    &-form {
        margin-top: 2rem;
        margin-bottom: 3rem;
        width: 38rem;
        max-width: 95%;

        @include bp-down(s){
            width: 25rem;
            margin-top: 4rem;
        }

        @include bp-down(xs){
            margin-top: 3rem;
            border-width: .3rem;
        }

        @include bp-down(xxs){
            margin-top: 2rem;
            margin-bottom: 2rem;
            border-width: .2rem;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__title {
            font-size: 1.8rem;
            color: rgba($color-secondary, .9);

            &.active {
                color: $color-primary;
            }

            @include bp-down(s){
                font-size: 1.6rem;
            }      
            
            @include bp-down(xxs){
                font-size: 1.4rem;
            }   
        }

        &__subtitle {
            font-size: 1.2rem;
            color: rgba($color-secondary, .9);

            @include bp-down(s){
                font-size: 1rem;
            }      
            
            @include bp-down(xxs){
                font-size: .9rem;
            }   
        }

        &__separator {
            width: 50%;
            height: .5rem;
            border-top: 5px solid rgba($color-secondary, .9);

            @include bp-down(xs){
                border-top: 3px solid rgba($color-secondary, .9);
                margin-top: .5rem;
            }
        }

        &__group {
            margin-bottom: 1.5rem;
        }

        &__label {
            margin-bottom: .5rem;
            color: $color-primary;
            font-weight: bold;
            font-size: 1.1rem;

            @include bp-down(s){
                font-size: 1rem;
            }

            @include bp-down(xs){
                font-size: .9rem;
            } 
        }

        &__label-sm {
            margin-bottom: 0rem;
            color: #fff;
            font-weight: bold;
            font-size: 1rem;

            @include bp-down(s){
                font-size: .9rem;
            }

            @include bp-down(xs){
                font-size: .8rem;
            } 
        }

        &__field {
            border-radius: 4px;
            border: 1px solid rgba($color-secondary, .9);
            height: 2.5rem;
            font-size: 1rem;

            @include bp-down(xs){
                height: 2rem;
                font-size: .9rem;
            }

            @include bp-down(xxs){
                height: 2rem;
                font-size: .8rem;
            }
        }

        &__submit {
            border: 1px solid $color-primary;
        }

        &__info {
            color: #fff;
            border: 1px solid $color-primary;
            border-radius: .5rem;
            padding: .5rem;
        }

    }

    & .valid-feedback, & .invalid-feedback {
        font-size: 90%;
    }

    & .invalid-feedback a {
        color: #dc3545;
        text-decoration: underline;
    }

    & .form-control:disabled {
        background-color: rgba($bg-color-box, .8);
    }

    & .no-border {
        border: none !important;
    }


}