.users-list {

    background-image: url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #fff;

    &-header {

        &-add-user {
            display: flex;
            justify-content: flex-end;
        }

    }

    &-download {
        display: flex;
        align-items: center;
    }

    & .users-list-filters {
        & label {
            margin-right: .5rem;
        }

        & input, & select {
            margin-right: 1rem;
        }
    }

    & table {
        font-size: .8rem;
        color: $color-blue-dark;
        width: 100% !important;

        & thead {
            background-color: $color-blue-dark;
            color: #fff;
            font-size: 1rem;

            & th {
                border-top: none;
            }
        }

    }

    & .dataTables_wrapper .dataTables_paginate .paginate_button {
        background-color: #fff;
        border: 1px solid $color-blue-dark;
        color: $color-blue-dark;
        cursor: pointer;

        &:hover {
            background: linear-gradient(to bottom, $color-blue 0%, $color-blue-dark 80%);
        }

        &.current {
            background: linear-gradient(to bottom, $color-blue 0%, $color-blue-dark 80%);
            font-weight: bold;
            color: #fff !important;
        }

        &.disabled, &.disabled:hover {
            background: #eee;
            cursor: default;
        }
    }

    & table button.btn { display: inline-block;}
}

.loader-default {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .5rem;
    color: $zb-blue;
    display: none;

    @include bp-down(s){
        width: 1.2rem;
        height: 1.2rem; 
    }

    &.available {
        display: block;
    }
}