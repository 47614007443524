.team-chat {
    height: 100%;
    background-color: #000;

    &-header {

    }

    &-main {

        &__title {
            color: $color-green !important;
        }

        &__team {
            border: 2px solid $color-primary;
        }

        &__link, &__link:hover {
            text-decoration: none;
        }

        & .btn {
            color: #fff;
            background-color: $color-green;
            border-color: $color-green;
            display: inline-block;
        }
    }

    &-all {

        &__title {
            color: $color-primary !important;
        }

        &-item {

            display: flex;
            align-items: center;

            &__logo {
                width: 6rem;
                margin-right: 1rem;

                @include bp-down(l){
                    width: 5rem;
                }

                @include bp-down(xxs){
                    width: 4rem;
                    margin-right: .5rem;
                }
            }

            &__name {
                font-size: 1.2rem;
                margin-bottom: 0;
                color: $color-primary;
                text-transform: capitalize;

                @include bp-down(l){
                    font-size: 1.1rem;
                }

                @include bp-down(xs){
                    font-size: 1rem;
                }

                @include bp-down(xxs){
                    font-size: .9rem;
                }
            }
        }
    }
    
}

.modal-team {
    & .modal-title {
        color: $color-primary;
    }
}


.main-team-coach-image-wrapper {
    position: relative;
    overflow: hidden;
    border: .2rem solid blue;
    border-radius: 10px;
    margin-bottom: 2rem;
    width: 6rem;
    min-width: 6rem;
    margin-right: 1rem;

    @include bp-down(l){
        width: 5rem;
        min-width: 5rem;
    }

    @include bp-down(xxs){
        width: 4rem;
        min-width: 4rem;
        margin-right: .5rem;
    }
}
.main-team-coach-name {
    position: absolute;
    bottom: -2px;
    left: -2px;
    width: calc(100% + 4px);
    margin-bottom: 0;
    padding: 4px;
    padding-top: 2px;
    font-size: .65rem;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    background-color: #fff;
    color: blue;
}