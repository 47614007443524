.save-the-date {

    text-align: center;
    color: #fff;

    &__calendar {
        width: 3rem;
        margin-bottom: 1rem;

        @include bp-down(xxs){
            width: 2.5rem;
        }
    }

    &-title {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 2rem;
    }

    &-separator {
        width: 60px;
        height: 1px;
        background-color: $color-secondary;
        margin: 0 auto;
    }

    &-day {
        font-weight: bold;
    }

    &-info {
        margin-top: 1rem;
        margin-bottom: 2rem;

        @include bp-down(xxs){
            margin-top: .5rem;
            margin-bottom: 1.5rem; 
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-around;
        max-width: 40rem;
        margin: 0 auto;

        & .link-wrapper {
            font-weight: bold;
        }

        & .link-button {
            font-weight: bold;
            width: 8rem;
        }

        @media (max-width: 500px){
            flex-direction: column;
            align-items: center;

            & .link-wrapper {
                margin-bottom: .5rem;
            }
        }
    }
}