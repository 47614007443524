.live-page-china {

    position: relative;
    padding-bottom: 5rem; //20rem;
    background-image: url(/assets/images/bg-page-new.jpg); /* fallback */
    // background-image: linear-gradient($bg-color-page, rgba($bg-color-page, .9)), url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: left top;
    background-size: cover;
    background-color: $bg-color-page;
    overflow: hidden;

    @include bp-down(s){
        background-size: 120%;
        padding-bottom: 15rem;
    }

    @include bp-down(xxs){
        padding-bottom: 10rem;
    }

    & h1 , & h2, & p {
        color: #fff;
    }

    & .live-page {

        &-main {
            margin-top: -3rem;

            @include bp-down(s){
                margin-top: 3rem;
            }
        }

        &-barcode {
            padding: 1rem;
            margin: 0 auto;
        }

        &-question {
            &-title {
                color: $color-green !important;
            }
        }

        .page-title {
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
            color: #fff;
            padding: .8rem;
            margin-bottom: .2rem;
        }

        &-poll {

            &__title {
                color: $color-primary !important;
            }

            &__info {
                & span {
                    color: $color-primary !important;
                }
            }
        }

        & .outer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            & .inner {
                width: 60%;
                font-size: 8vw;
                font-weight: bold;
                color: #fff;
                text-shadow: 2px 2px 5px #fff;
                transform:  rotate(-30deg);
                opacity: .4;

                &.agenda {
                    width: 90%;
                    font-size: 4rem;
                    transform:  rotate(-45deg);
                }
            }
        }
    }
}
