/* ========================================
	Navigation
======================================== */

.nav-picture a {
    font-size: 0.9rem;
    color: $color-blue-dark;
  }
  
  .nav-picture .nav-link.active {
    color: #fff;
    background-color: $color-blue-dark;
  }
  
  .tab-content-picture{
    padding: 0.7rem 0;
  }
  
  /* ========================================
      Take a picture
  ======================================== */


  // -- take a picture modal
.take-picture-modal {
  & .modal-content {
      background-color: $color-blue;

      & .close {
          color: $color-blue-light;
      }

      & .take-picture-wrapper {
          margin-top: 1rem;
      }
  }
}
  
  .picture-necessary {
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
  }
  
  #receivedImage { 
    width: 100%;    /* for small screen, occupy the entire screen */
    max-width: 70vh;  /* for desktop, i want to limit the size of the image */
    height: auto; 
    display: none;
    border: 1px solid $color-blue-dark;
  }
  .portrait #receivedImage {
    max-width: 52.5vh;
  }

  #uploadedImage {
    max-height: 50vh;
  }
  
  .display-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    // height: 70vh;
    margin: 0 auto;
    overflow: hidden;
    border: 1px solid $color-blue-dark;
  }
  .display-cover:after {
    content: "";
    display: block;
    // padding-bottom: 56.25%;
  }

  .portrait .display-cover {
    // width: 52.5vh;
    // height: 52.5vh;
    width: 100%;
  }
  .portrait .display-cover:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .video-container {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }
  
  .video-player {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // left: -23vh;
  }
  .portrait .video-player {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    left: calc(-40%);
  }
  
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {

    .display-cover {
      width: calc(100vw - 3rem);
    }
    .portrait .display-cover {
      width: calc(100vw - 3rem);
      // height: calc(100vw - 3rem);
    }
  
    .video-player {
      height: 100%;
      width: 100%;
      left: 0;
    }
  }
  
  /*.video-delimitator {
    position: absolute;
    border: 2px solid red;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;  
  }*/
  
  .screenshot-image {
    width: 100%;
  }
  
  .video-options .custom-select {
    position: absolute;
    left: 10px;
    top: 10px;
    width: auto;
    font-size: 0.8rem;
  }
  
  .video-options .switch-camera {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 35px;
    padding: 10px;
    background-color: #fff;
    cursor: pointer;  
    outline: none;
  }
  
  .controls {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
  }
  
  .controls > button {
    width: 45px;
    height: 45px;
    text-align: center;
    border-radius: 100%;
    margin: 0 6px;
    background: transparent;
  }
  .controls > button:hover svg {
    color: white !important;
  }
  
  @media (min-width: 300px) and (max-width: 400px) {
    .controls {
      flex-direction: column;
    }
    .controls button {
      margin: 5px 0 !important;
    }
  }
  
  .controls > button > svg {
    height: 20px;
    width: 18px;
    text-align: center;
    margin: 0 auto;
    padding: 0;
  }
  
  .controls button:nth-child(1) {
    border: 2px solid $color-blue-dark;
  }
  .controls button:nth-child(1):focus, .controls button:nth-child(1):active, .controls button:nth-child(1):hover {
    background-color: $color-blue-dark !important;
    border: 2px solid $color-blue-dark !important;
  }
  .controls button:nth-child(1) svg {
    color: $color-blue-dark;
  }
  .controls button:nth-child(1):focus svg, .controls button:nth-child(1):active svg, .controls button:nth-child(1):hover svg {
    color: #fff;
  }
  
  .controls button:nth-child(2) {
    border: 2px solid #008496;
  }
  .controls button:nth-child(2) svg {
    color: #008496;
  }
  
  .controls button:nth-child(3) {
    border: 2px solid #00B541;
  }
  .controls button:nth-child(3) svg {
    color: #00B541;
  }
  
  .controls > button {
    width: 45px;
    height: 45px;
    text-align: center;
    border-radius: 100%;
    margin: 0 6px;
    background: transparent;
  }
  .controls > button:hover svg {
    color: white;
  }
  
  .user-icon {
    position: absolute;
    width: 90%;
    height: 90%;
    opacity: .1;
  }
  
  .user-icon {
    fill: #fff;
  }
  



  /***** Filerobot image editor override *****/

  .filerobot-image-editor-root {
    & .modal-overlay {
      background-color: $bg-color-page;
      opacity: 1;
    }

    // -- header
    & .heNuus {
      border-color: $color-blue;
      background: $color-blue;
    }

    & .jgvbXR, & .epngpB, & .kAFRsS {
      color: $color-text;
    }

    // --body
    & .ghcLPq {
      background: $color-blue;
    }

    // -- footer
    & .kTrRHo {
      background-color: $color-blue;
    }

    & .dndvoT, & .dVRavq, & .eEJDia {
      color: $color-text;
    }
  }

  
  #scaleflex-image-edit-box {
    height: auto;
  }
  
  /* "Filerobot Image Editor" text */
  .sc-kkGfuU {
    display: none;
  }
  
  /* Rotate left, right & vertically button */
  .sc-iAyFgw.sc-cMljjf.sc-bbmXgH.bXDnWl {
    &:nth-child(1), &:nth-child(2), &:nth-child(4) {
      display: none!important;
    }
  }
  /* flip horizontally */
  .dcPGLP {
    margin-bottom: 10px;
  }

  /* Rotator range */
  .sc-dNLxif.dcPGLP > div:nth-child(2) {
    display: none;
  }
  
  /* Exposure & Saturation */
  .sc-dqBHgY.kykhDA.image-editor-range-wrapper.image-editor-range {
    // -- create a vertical line in the middle
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      background: rgb(52, 68, 76);
      height: 5px;
      width: 2px;
      top: 45px;
      left: calc(50% - 1px);
    }
    // -- fix input range width 
    & #range {
      width: 100%;
    }
    // -- hide 
    &:nth-child(3), &:nth-child(4) {
      display: none!important;
    }
  }
  
  /* cancel and apply or ok buttons */
  button.sc-iAyFgw.jCLhqB {
    background-color: #95C11F !important;
    border-color: #95C11F !important;
  }
  
  button.sc-iAyFgw.sc-cMljjf.iYbGl {
    background-color: $color-blue-dark !important;
    border-color: $color-blue-dark !important;
    color: #fff !important;
  }

  @media (max-width: 768px) {
    .sc-jWBwVP.ffokiB {
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
    }
  }

  
  @media (max-width: 600px) {
  
    .sc-eHgmQL.lprvdC {
      height: 60px;
    }
  
    /* central bar */
    .sc-bdVaJa.kTcthk {
      height: 60px;
      width: calc(100% - 60px);
    }
    .image-editor-range-wrapper {
      width: 120px !important;
      padding: 15px 5px 0px !important;
    }
    .image-editor-range-wrapper::after {
      top: 30px !important;
    }  
    .image-editor-range-wrapper input {
      width: 100px !important;
      margin: auto !important;
      border-radius: 2px !important;
      height: 4px !important;
      -webkit-appearance: none !important;
    }
    .image-editor-range-wrapper input {
      margin-bottom: 0px !important;
    }  
    .image-editor-range-wrapper label {
      font-size: 11px;
      padding-top: 16px;
    }
  
    .sc-bwzfXH.jifhaY {
      min-width: 60px;
      min-height: 40px;
      padding: 5px;
    }
  
    /* Icons + text filters */
    .sc-bwzfXH.jifhaY .sc-htpNat {
      height: 30px;
      font-size: 30px;
    }
  
    .sc-bxivhb.fXEBgN {
      line-height: 15px;
    }
  
    /* cancel and apply or ok buttons */
    .sc-hSdWYo.eosBmd {
      width: 60px;
      padding: 2px 5px;
    }
    .sc-iAyFgw.laSuty {
      width: 60px;
      padding: 2px 5px;
    }
  
  }
  
  @media (max-width: 400px) {
    /* central bar */
    .image-editor-range-wrapper {
      width: 100px !important;
    }
    .image-editor-range-wrapper input {
      width: 80px !important;
    }
  }
  
  /* ========================================
      Upload your picture
  ======================================== */
  
  #upload-your-picture .input-group {
    margin-top: 2rem;
  }


  // TAKE PICTURE PREVIEW MODAL
  .take-picture-preview {
    & .modal-content {
      background-color: $color-blue;
      border: 1px solid $color-blue-dark;
    }
  }