.suggest {

    &-title {
        color: $color-blue;
        margin-bottom: 1rem;
    }


    &-textarea {
        width: 100%;
        padding: .5rem 1rem;
        color: #555;
        border: 1px solid $color-blue-dark;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

        &:focus {
            outline: 0;
        }
    }

}