.poll-user {

    position: relative;

    &-header {
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        padding: .8rem;
        margin-bottom: .2rem;

        @include bp-down(s) {
            font-size: 1.2rem;
        }

        @include bp-down(xs) {
            font-size: 1rem;
        }
    }

    &-reload {
        position: absolute;
        color: #fff;
        left: .5rem;
        bottom: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        &-icon {
            width: 1.4rem !important;
            height: 1.4rem;
        }

        &-text {
            margin-bottom: 0;
        }

    }

}