/* OVERRIDE CSS STYLE react-images module */

.css-yrspe {
    z-index: 100 !important;
}

.css-1rbq2qy {
    z-index: 999 !important;
}

.react-images {

    &__navigation {

        // LEFT - RIGHT ARROW
        & button.css-1h82jk3, & button.css-xfk84m {

            @include bp-down(m) {
                height: 40px;
                width: 40px;
                margin-top: -20px;
            }

            @include bp-down(s) {
                height: 30px;
                width: 30px;
                margin-top: -15px;
            }

            & svg {

                @include bp-down(m) {
                    height: 40px;
                    width: 40px;
                }

                @include bp-down(s) {
                    height: 30px;
                    width: 30px;
                }
            }
        }

        // RIGHT ARROW
        & button.css-xfk84m {

            @include bp-down(m) {
                right: 15px;
            }

            @include bp-down(s) {
                right: 10px;
            }
        }

        // LEFT ARROW
        & button.css-1h82jk3 {

            @include bp-down(m) {
                left: 15px;
            }

            @include bp-down(s) {
                left: 10px;
            }
        }


    }

    &__view-image {
        height: auto;
        max-height: 100vh;
        max-width: 100vw;
    }
}