.interactive-page-reg-success {
    min-height: 80vh;
    background-image: url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;
}

.interactive-page-reg-success-text {

    color: #fff;
    text-align: center;
    padding: 4rem 1rem;
    max-width: 60rem;
    margin: 0 auto;

    @include bp-down(xs){
        padding: 2rem 1rem;
    }

    & h1 {
        margin-bottom: 2rem;
    }

    & a {
        color: $color-primary;
        outline: none;
    }
}