.interactive-page-login {

    padding-bottom: 4rem;
    background-image: url(/assets/images/bg-page-new.jpg);
    // background-image: linear-gradient($bg-color-page, rgba($bg-color-page, .9)), url(/assets/images/bg-page-new.jpg);
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;

    @include bp-down(s) {
        padding-bottom: 2rem;
    }

    @include bp-down(xxs) {
        padding-bottom: 0rem;
    }
    
    &__header {
        max-width: 1200px;
        margin: 2rem auto;
        display: flex;

        &-title-wrapper {
            display: flex;
            margin-top: 1rem;
        }

        &-image-wrapper {
            width: 6rem;
            margin-right: 1rem;
            margin-top: -1rem;

            @include bp-down(l){
                margin-top: 1rem;
            }

            @include bp-down(xxs){
                width: 5rem;
                margin-top: 0rem;
            }

        }

        &-text-wrapper {

            & h1 {
                position: relative;
                font-family: 'Electra LT Std Display', Times, 'Times New Roman', serif;
                font-size: 3rem;
                line-height: 3rem;
                padding: .5rem;
                padding-right: 1.2rem;
                background-color: rgba(255,255,255,.8);

                @include bp-down(xs){
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }

                @media (max-width: 450px) {
                    font-size: 1.9rem;
                    line-height: 1.9rem;
                }

                @include bp-down(xxs){
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                }

                & span.dot {
                    position: absolute;
                    bottom: 1.4rem;
                    margin-left: -0.15rem;
                    font-size: 5rem;
                    color: $color-red;

                    @include bp-down(l){
                        bottom: 1.3rem;
                    }

                    @include bp-down(xs){
                        font-size: 4rem;
                    }

                    @include bp-down(xxs){
                        font-size: 3rem;
                        bottom: 1rem;
                    }
                }
            }

            & h2 {
                font-family: 'Gotham', Times, 'Times New Roman', serif;
                font-size: 1rem;
                text-align: left;
                color: #fff;
                text-transform: uppercase;

                @include bp-down(s){
                    font-size: .9rem;
                }

                @include bp-down(xxs){
                    font-size: .8rem;
                }
            }

            &.mobile {

                & h1 {
                    background-color: transparent;
                    padding: 0;
                }

                & span.text-line-wrapper {
                    display: inline-block;
                    background-color: rgba(255,255,255,.8);
                    padding: .5rem;
                    padding-bottom: .3rem;
                    margin-bottom: .6rem;

                    &--2 {
                        padding-right: 1.2rem;
                    }

                    @include bp-down(xxs){
                        padding: .4rem;
                        padding-bottom: .2rem;
                        margin-bottom: .4rem;

                        &--2 {
                            padding-right: .6rem;
                        }
                    }
                }

            }

        }

        &-countdown {
            width: 80%;

            &-wrapper {
                display: flex;
                justify-content: flex-end;

                @include bp-down(s){
                    justify-content: center;
                    padding-right: 0;
                    margin-top: 2rem;
                }
            }

            @include bp-down(m){
                width: 100%;
            }

            @include bp-down(s){
                width: 80%;
            }
        }

        &-countdown-title {
            text-transform: uppercase;
            margin-bottom: .2rem;
            font-size: .7rem;
            color: #fff;
        }

        &-countdown-img {
            padding: 1rem;
            padding-bottom: .5rem;
            padding-right: .4rem;
            border: 1px solid #fff;
        }
    }
}
