.poll-user-question {

    &-header {

        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        &__text {
            margin-bottom: 0;
            color: #fff;
            & span {
                font-weight: bold;
            }
        }

        & img {
            margin-top: .5rem;
            max-width: 400px;

            @include bp-down(s){
                max-width: 100%;
            }
        }
    }

    &-body {

        &-text {
            color: #fff;
        }

        &-answers {

            margin-bottom: 2rem;

            & .question-open {
                width: 100%;
                height: 10rem;
            }

        }

    }

    &-footer {

        display: flex;
        justify-content: flex-end;

        &-send {

            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            cursor: pointer;
    
            &__icon {
                width: 3rem !important;
                height: 3rem;
                margin-bottom: .5rem;

                @include bp-down(xs) {
                    width: 2rem !important;
                    height: 2rem;
                }
            }
        }
    }

}