.pagination {

    & .page-link {
        font-size: 1rem;
        padding: 0.5rem 0.8rem;
        color: $color-primary;
        cursor: pointer;

        @include bp-down(m) {
            font-size: .95rem;
            padding: 0.4rem 0.7rem;
        }
    
        @include bp-down(s) {
            font-size: .9rem;
            padding: 0.35rem 0.6rem;
        }
    
        @include bp-down(xs) {
            font-size: .85rem;
            padding: 0.3rem 0.5rem;
        }
    }

    & .page-item.active .page-link {
        background-color: $color-primary;
        border-color: $color-primary;
    }

}