.options {

    &-title {
        color: $color-blue;
        margin-bottom: 1rem;
    }

    & .option {
        min-width: 5rem;
        margin: 0 2rem;
        padding: .5rem 1rem;
        cursor: pointer;
        border-radius: 4px;
        font-weight: normal;
        color: $zb-grey;

        @include bp-down(s) {
            min-width: 4rem;
            margin: 0 1.5rem;
            padding: .4rem .8rem;
        }

        @include bp-down(xs) {
            min-width: 3.5rem;
            margin: 0 1rem;
            padding: .3rem .6rem;
        }

        &:hover {
            color: $color-blue;
            -webkit-transition: color 0.2s linear;
            -moz-transition: color 0.2s linear;
            -o-transition: color 0.2s linear;
            transition: color 0.2s linear;   
        }

        &--sel {
            background-color: $color-blue-dark;
            color: #fff;

            &:hover {
                color: #fff;
            }
        }

        &-input {
            position: absolute;
            visibility: hidden;
        }

        &-icon {
            margin-right: .5rem;
        }

        &-text {
            font-size: 1rem;

            @include bp-down(m) {
                font-size: .9rem;
            }

            @include bp-down(s) {
                font-size: .8rem;
            }

            @include bp-down(xs) {
                font-size: .7rem;
            }
        }
    }

}