.poll-title {

    display: flex;
    flex-direction: row;
    margin-bottom: .4rem;

    &-text {

        width: 100%;

        &-header {
            width: 100%;
            @include bg-gradient($color-primary);
            font-weight: bold;
            color: #fff;
            padding: .2rem 1rem;
        }
    }

    &-image {

        width: 70px;

        & > * {
            float: right;
        }

    }

}