/* OVERRIDE CSS COLOR react-bootstrap */


/* OVERRIDE CSS STYLE react-bootstrap modal */
.modal {
    .modal-header, .modal-footer {

        @include bp-down(m){
            padding: .8rem 1rem;
        }

        @include bp-down(s){
            padding: .5rem 1rem;
        }
    }
}

/* OVERRIDE CSS STYLE react-bootstrap nav */
.dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: $color-primary;

    & .nav-link {
        color: #fff !important;
        background-color: $color-primary;
    }
}