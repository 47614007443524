.poll-user-answer {
                
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: .5rem;

    @include bp-down(xs){
        margin-bottom: .6rem;
        margin-top: .3rem;
    } 

    &__title {

        color: #fff;

        & label {
            display: inline-flex;
            align-items: center;
            margin-bottom: 0;
        }

        & svg {
            color: #fff;
            width: 1.5rem !important;
            height: 1.5rem !important;
            margin-right: .5rem;

            @include bp-down(s){
                width: 1rem !important;
                height: 1rem !important;
                margin-right: .5rem;
            }
        }

    }

    &__image {

        & img {
            margin-top: .5rem;
            margin-bottom: 1rem;
            margin-left: .75rem;
            padding-left: 1rem;
            border-left: 1px solid #ccc;
            max-width: 400px;

            @include bp-down(s){
                max-width: 95%;
                margin-left: .5rem;
                padding-left: 1rem;
                margin-top: .3rem;
            }
        }
    }
  
}