.footer {

    background-color: $color-blue-dark;
    color: #fff;

    & a {
        color: #fff;

        &:hover, &:visited {
            color: #fff;
        }
    }

    &-company {

        background-color: $color-blue-dark;

        &-disclaimer {
            padding-bottom: 2rem;
            padding-top: 2rem;

            @include bp-down(s){
                padding: 2rem;
                padding-bottom: 1.5rem;
            }

            @include bp-down(xxs){
                padding: 1rem;
            }

            &__text {
                text-align: justify;
            }
        }

        &-content {
            padding: 2rem 1rem;

            @include bp-down(s){
                padding: 1.5rem 1rem;
            }

            @include bp-down(xxs){
                padding: 1rem;
            }
        }
    }

    &-poweredby {
        padding: .5rem 0;
        background-color: #808285;

        @include bp-down(s){
            padding: .3rem 0;
        }

        @include bp-down(xxs){
            padding: .2rem 0;
        }
    }
}

.social-icon {
    height: 1.8rem !important;
    width: 1.8rem !important;
    padding: 0.3rem;
    border-radius: 50%;
    border: 1px solid #fff;
    transition: all .6s;
    
    &:hover {
        transform: rotate(360deg);
        border-color: transparent;
    }

    &.facebook:hover {
        background-color: #3B5998;
    }
    &.twitter:hover {
        background-color: #33ccff;
    }
    &.youtube:hover {
        background-color: #BD3518;
    }
    &.linkedin:hover {
        background-color: #007BB6;
    }
}