.live-page-photos {

    border: 1px solid $bg-color-box;
    background-color: $bg-color-box;
    padding: .5rem 1rem;

    &__title {
        margin-bottom: 1rem;

        @include bp-down(s){
            margin-top: 0rem;
        }
    }

    &-list {

    }

    &-item {

    }

}